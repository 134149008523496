import * as React from 'react';

import {Chip, styled, Tooltip, tooltipClasses, TooltipProps, Typography} from '@mui/material';
import {CommonInputProps, useListContext, useTranslate} from "react-admin";
import {useEffect, useState} from "react";
import {blue} from '@mui/material/colors';

import {
    startOfWeek,
    startOfMonth,
    startOfYear,
} from 'date-fns';
import dayjs from "dayjs";
import {isEmptyObject, isEmptyString} from "./util";

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

interface Props extends CommonInputProps {
    dateField?: string
}

const QuickDateFilter = (props: Props) => {
    const translate = useTranslate();
    const field = isEmptyObject(props.dateField) ? "tmp_dateReported" : props.dateField;

    const {filterValues, displayedFilters, setFilters, hideFilter} = useListContext();
    const [localState, setLocalState] = useState({
        period: isEmptyString(filterValues?.period) ? "choose_time" : filterValues?.period,
        open: false
    })
    useEffect(() => {
        if (localState.period !== "choose_time") {
            const objDate = {} as any
            objDate[`${field}_greaterThanOrEqual`] = getStart(localState.period);
            objDate[`${field}_lessThanOrEqual`] = getEnd(localState.period);
            console.warn(objDate)
            setFilters({
                ...filterValues, ...objDate, period: localState.period
            }, displayedFilters);
        }
    }, [localState.period])

    useEffect(() => {
        if (getStart(localState.period) !== filterValues[`${field}_greaterThanOrEqual`] ||
            getEnd(localState.period) !== filterValues[`${field}_lessThanOrEqual`]) {

            setLocalState({...localState, period: "choose_time"})
        }
    }, [filterValues[`${field}_greaterThanOrEqual`], filterValues[`${field}_lessThanOrEqual`]])


    const formatFilter = "YYYY-MM-DD";
    const now = new Date();
    const getStart = (period: string) => {
        switch (period) {
            case "today":
                return dayjs().format(formatFilter)
            case "yesterday":
                return dayjs().subtract(1, "day").format(formatFilter)
            case "this_month":
                return dayjs(startOfMonth(now)).format(formatFilter)
            case "last_month":
                return dayjs(startOfMonth(now)).subtract(1, "month").format(formatFilter)
            case "last_2_month":
                return dayjs(startOfMonth(now)).subtract(2, "month").format(formatFilter)
            case "this_week":
                return dayjs(startOfWeek(now)).add(1, "day").format(formatFilter)
            case "last_week":
                return dayjs(startOfWeek(now)).add(1, "day").subtract(1, "week").format(formatFilter)
            case "this_year":
                return dayjs(startOfYear(now)).format(formatFilter)
        }
        return dayjs().format(formatFilter);
    }

    const getEnd = (period: string) => {
        switch (period) {
            case "today":
                return dayjs().format(formatFilter)
            case "yesterday":
                return dayjs().subtract(1, "day").format(formatFilter)
            case "this_month":
                return dayjs().format(formatFilter)
            case "last_month":
                return dayjs(startOfMonth(now)).subtract(1, "day").format(formatFilter)
            case "last_2_month":
                return dayjs(startOfMonth(now)).subtract(1, "month").subtract(1, "day").format(formatFilter)
            case "this_week":
                return dayjs().format(formatFilter)
            case "last_week":
                return dayjs(startOfWeek(now)).format(formatFilter)
            case "this_year":
                return dayjs().format(formatFilter)
        }
        return dayjs().format(formatFilter);
    }

    const getRandomIndex = (): any => {
        return Math.floor(Math.random() * 10) * 50;
    }
    const getRandomColor = (p: string): any => {
        if (p === "choose_time") {
            return "gray";
        }
        return JSON.parse(JSON.stringify(blue))[getRandomIndex()];
    }

    return <HtmlTooltip
        arrow
        onClose={() => setLocalState({...localState, open: false})}
        open={localState.open}
        PopperProps={{
            disablePortal: true
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
            <React.Fragment>
                {["today", "yesterday", "this_month", "last_month", "last_2_month", "this_week", "last_week"]
                    .filter(period => period !== localState.period).map((period, idx) => {
                        return <span key={`periods-${idx}`}>
                            <Chip sx={{marginBottom: 1, backgroundColor: getRandomColor(period)}}
                                  onClick={() => setLocalState({...localState, period, open: false})}
                                  label={translate(`resources.report.filters.${period}`)}/> &nbsp;
                        </span>
                    })}
            </React.Fragment>
        }
    >
        <Chip sx={{marginBottom: 1, backgroundColor: getRandomColor(localState.period)}}
              label={translate(`resources.report.filters.${localState.period}`)}
              onClick={() => setLocalState({...localState, open: !localState.open})}
        />
    </HtmlTooltip>
};

export default QuickDateFilter