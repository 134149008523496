import * as React from 'react';
import {Fragment} from 'react';
import {
    ListItem,
    ListItemText,
    Link as MuiLink,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {
    useCreatePath,
    TextField,
    useRecordContext, RecordContextProvider, useListContext,
} from 'react-admin';
import WebsiteReferenceField from "../websites/WebsiteReferenceField";


const EntityListItem = () => {
    const record = useRecordContext<any>();
    const createPath = useCreatePath();
    if (!record) {
        return null;
    }
    return (
        <MuiLink
            to={createPath({
                resource: 'websites',
                type: 'edit',
                id: record.id,
            })}
            component={Link}
            underline="none"
            color="inherit"
        >
            <ListItem button>
                <ListItemText
                    primary={
                        <Fragment>
                            <TextField
                                source="display" variant={"subtitle1"}
                            />
                            <br/>
                            <WebsiteReferenceField link={false}/>
                            <br/>
                        </Fragment>
                    }
                    secondary={`-`}
                    secondaryTypographyProps={{noWrap: false}}
                />
            </ListItem>
        </MuiLink>
    );
};

interface Props {
    context: any
}

const EntityList = (props: Props) => {
    const {context} = props;

    return (context?.data?.map((review: any) => (
        <RecordContextProvider value={review} key={review.id}>
            <EntityListItem/>
        </RecordContextProvider>
    )))
}

export default EntityList;