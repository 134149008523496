export const isEmptyObject = (obj: any) => {
    return typeof obj === "undefined" || obj === null;
}

export const isEmptyArray = (obj: any) => {
    return isEmptyObject(obj) || obj.length === 0;
}

export const isEmptyString = (str: any) => {
    return isEmptyObject(str) || str === "";
}

export const FieldMinWidth90 = {minWidth: '90%'}
export const FieldMinWidth60 = {minWidth: '60%'}
export const FactorChoices = [
    {id: 'factor', name: 'Advertiser'},
    {id: 'factor-user', name: 'Publisher'},
];
export const StatusChoices = [
    {
        id: 'activate',
        name: 'activate',
    },
    {
        id: 'deactivate',
        name: 'deactivate',
    }
];
export const LogoChoices = [
    {
        id: 'zmedia-topright',
        name: 'ZMgedia top right'
    },
    {
        id: 'zmedia-topleft',
        name: 'ZMgedia top left'
    }
]