import {useCreateSuggestionContext} from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';
import {useState} from "react";

interface Props {
    addChoice?: any
    currentType?: any
}

const CreateIp = (props: Props) => {
    const {addChoice, currentType} = props;
    const {onCancel, onCreate} = useCreateSuggestionContext();
    const [value, setValue] = useState('');

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const newOption = {id: value, name: value, type: currentType};
        addChoice(newOption);
        setValue('');
        onCreate(newOption);
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="Role name"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateIp;