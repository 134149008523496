import * as React from 'react';
import {
    Typography,
} from '@mui/material';
import {
    useTranslate,
    useGetManyReference,
    useRecordContext,
    TextField,
    NumberField,
    Datagrid
} from 'react-admin';
import CustomDateField from "../fields/CustomDateField";
import {useNavigate} from "react-router-dom";


interface Props {
    title: string
}

const WebsitesOfPublisher = (props: Props) => {
    const {title} = props
    const record = useRecordContext<any>();
    const translate = useTranslate();
    if (typeof record === "undefined") {
        return null;
    }
    const {data, isLoading, error} = useGetManyReference(
        'websites',
        {
            target: 'userId',
            id: record.id,
            pagination: {page: 1, perPage: 10},
            sort: {field: 'id', order: 'DESC'}
        }
    );

    const sort = {field: 'id', order: 'DESC'} as any;

    const navigate = useNavigate();
    return (
        <>
            {data && data.length > 0 && <Typography variant="h6" gutterBottom>
                {translate(title)}
            </Typography>}
            {(data && data.length > 0) && <Datagrid rowClick={(websiteId) => {
                navigate(`/websites/${websiteId}`)
                return false;
            }} data={data} sort={sort}>
                <TextField source="domain"/>
                <NumberField source="rate"/>
                <CustomDateField source="rateStartDate"
                                 format="DD/MM/YYYY"/>
                <CustomDateField source="rateEndDate"
                                 format="DD/MM/YYYY"/>
            </Datagrid>}
        </>
    );
}

export default WebsitesOfPublisher;