import * as React from 'react';
import {
    Edit,
    TextInput,
    BooleanInput,
    SelectArrayInput,
    SelectInput,
    useTranslate,
    Form, useRecordContext,
} from 'react-admin';
import {Card, CardContent, Box, Grid, Typography, Link} from '@mui/material';


import {Spacer} from "../fields/Space";
import CreateOnlyTextInput from "../fields/CreateOnlyTextInput";
import WebsitesOfPublisher from "./WebsitesOfPublisher";
import {FieldMinWidth90, isEmptyObject} from "../fields/util";
import ManagedUser from "./ManagedUsers";
import {EditToolbar} from "../fields/EditWithToolbar";

export const EntityTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();
    return (<span>
        {isEmptyObject(record?.id) ? translate('resources.publishers.page.create') : `${translate('resources.publishers.name')}: ${record.login}`}
    </span>)
}

const EntityEdit = () => (
    <Edit redirect={"show"} title={<EntityTitle/>} component="div">
        <PublisherForm/>
    </Edit>
);

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.login) {
        errors.login = 'ra.validation.required';
    }
    if (!values.email) {
        errors.email = 'ra.validation.required';
    }
    if (!values.authorities || values.authorities.length === 0) {
        errors.authorities = 'ra.validation.required';
    }
    return errors;
};

export const LANGS = [
    {
        id: 'vi',
        name: "Tiếng Việt"
    },
    {
        id: 'en',
        name: "English"
    }
]

export const ROLES = [
    {
        id: 'ROLE_USER',
        name: 'ROLE_USER',
    },
    {
        id: 'ROLE_ADMIN',
        name: 'ROLE_ADMIN',
    },
    {
        id: 'ROLE_SUPER_ADMIN',
        name: 'ROLE_SUPER_ADMIN',
    },
    {
        id: 'ROLE_ACCOUNTING',
        name: 'ROLE_ACCOUNTING'
    },
    {
        id: 'ROLE_ADMIN_DATA',
        name: 'ROLE_ADMIN_DATA'
    },
    {
        id: 'ROLE_VIEW_MATCHED_REQUEST',
        name: 'VIEW_MATCHED_REQUEST'
    },
    {
        id: 'ROLE_VIEW_COVERAGE',
        name: 'VIEW_COVERAGE'
    },
    {
        id: 'ROLE_VIEW_FILLRATE',
        name: 'VIEW_FILLRATE'
    },
    {
        id: 'ROLE_ANALYTIC',
        name: 'ANALYTIC'
    },
    {
        id: 'ROLE_VIEW_MULTIPLE_CURRENCIES',
        name: 'VIEW_MULTIPLE_CURRENCIES'
    }
]


export const PublisherForm = () => {
    const translate = useTranslate();

    return (
        <Form validate={validateForm}>
            <Box maxWidth="50em">
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4}>

                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.publishers.fieldGroups.login'
                                    )}
                                </Typography>

                                <Grid item xs={12} sm={12} md={12}>
                                    <CreateOnlyTextInput source="login" isRequired
                                                         isEdit={(formData: any) => !(formData.id > 0)}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <CreateOnlyTextInput source="email" isRequired
                                                         isEdit={(formData: any) => !(formData.id > 0)}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box mt={2}>
                                        <BooleanInput
                                            row={true}
                                            source="activated"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box mt={2}>
                                        <SelectInput sx={FieldMinWidth90}
                                                     isRequired
                                                     fullWidth
                                                     source="langKey"
                                                     choices={LANGS}
                                        />
                                    </Box>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.publishers.fieldGroups.name'
                                    )}
                                </Typography>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput source="firstName" sx={FieldMinWidth90}
                                                   name={'firstName'}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput fullWidth name={"lastName"} sx={FieldMinWidth90} source="lastName"/>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <SelectArrayInput sx={FieldMinWidth90}
                                                          isRequired
                                                          fullWidth
                                                          source="authorities"
                                                          choices={ROLES}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Box mt={2}>
                                            <TextInput fullWidth
                                                       source="password"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>

                            </Grid>
                        </Grid>
                        <Spacer/>
                        <ManagedUser title={'resources.publishers.fieldGroups.managed'}/>
                        <Spacer/>

                        <WebsitesOfPublisher title={'resources.publishers.fieldGroups.websites'}/>
                        <Spacer/>
                    </CardContent>
                    <EditToolbar field={"login"}/>
                </Card>
            </Box>
        </Form>
    );
};


export default EntityEdit;
