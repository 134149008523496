import {
    Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import {
    Datagrid,
    TextField
} from "react-admin";
import * as React from "react";
import {fetchJson} from "../App";
import {useEffect, useMemo} from "react";


interface AsideProps {
    setState: (evt: any) => void,
    state: any;
}

interface AsideViewProps extends AsideProps {
    ab?: any;
}

const Aside = (props: AsideProps) => {
    useEffect(() => {
        if (typeof props.state.id !== "undefined") {
            fetchJson(`/api/codes/${props.state.id}/impression`,
                {method: 'GET'})
                .then(({
                           headers,
                           json
                       }) => {
                    props.setState({...props.state, log: json})
                })
        }

    }, [props.state.id])

    const displayButton = props.state.show === 'show' ? 'none' : 'block'
    return (<>
        <Box maxWidth={1 / 2}>
            <AsideView  {...props}/>
            <Button onClick={() => {
                props.setState({...props.state, show: props.state.show === 'show' ? 'hide' : 'show'})
            }}
                    sx={{sx: 'none', lg: displayButton}}>{displayButton === 'block' ? 'Preview' : 'Hide'}</Button>
        </Box></>)
}


const AsideView = (props: AsideViewProps) => {
    const display = props.state.show === 'show' ? 'block' : 'none'
    return (<div style={{display}}>
        <iframe width={'100%'} src={`/api/public/codes/${props.state.id}/raw`}/>
        <div>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {props.state.log?.query_result?.data?.columns && props.state.log?.query_result?.data?.columns.map((c: any, idx: any) => {
                                return <TableCell key={`columns-${idx}`}>{c.name}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.state.log?.query_result?.data?.rows && props.state.log?.query_result?.data?.rows.map((row: any, idx: any) => {
                            const columns = props.state.log?.query_result?.data?.columns;

                            return (
                                <TableRow
                                    key={idx}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    {columns.map((ci: any, cindex: any) => {
                                        return (
                                            <TableCell key={`cid-${cindex}`} component="th" scope="row">
                                                {row[ci.name]}
                                            </TableCell>)
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>


        </div>
    </div>);
}

export default Aside;