import AdvertiserIcon from '@mui/icons-material/Person3';

import AdvertiserList from './List';
import AdvertiserEdit from './Edit';

export default {
    list: AdvertiserList,
    edit: AdvertiserEdit,
    icon: AdvertiserIcon,
};
