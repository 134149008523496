import * as React from 'react';
import {Box} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useInput, useListContext, useStore, useTranslate, CommonInputProps} from 'react-admin';
import dayjs, {Dayjs} from "dayjs";

interface Props extends CommonInputProps {
    source: string,
    name?: string,
    i18Key?: string
}

import './style.css'
import {isEmptyObject, isEmptyString} from "./util";

const DatePickerFormatAble = (props: Props) => {
    const {source, resource} = props;
    const {
        displayedFilters,
        filterValues,
        setFilters,
    } = useListContext();
    const {id, field, formState} = useInput({source});
    const translate = useTranslate();

    const searchClick = () => {
        const newFilter = {...filterValues};
        newFilter[source] = getDefaultValue(field.value);
        if (setFilters) {
            setFilters(newFilter, displayedFilters);
        }
    }


    const onChange = (evt: any) => {
        const curDate = dayjs(evt);
        if (curDate.isValid() && curDate.get('year') > 1970) {
            field.onChange(curDate.format("YYYY-MM-DD"));
            searchClick()
        }
    }

    const getDefaultValue = (filterValue: any) => {
        return isEmptyString(filterValue) ? null : dayjs(filterValue, "YYYY-MM-DD");
    }
    const errorObject = formState.errors[source];
    const getKey = (obj: any) => {
        return obj?.message
    }
    const key = isEmptyObject(props.i18Key) ? `.filters.${source}` : `.${props.i18Key}`
    return (
        <Box display="flex" alignItems="flex-end">
            <span id={id} className={`${isEmptyObject(formState.errors[source]) ? '' : 'datepicker-alert'} any`}
                  style={{width: "100%"}}>
                <DatePicker
                    sx={{width: "100%"}}
                    format={"DD/MM/YYYY"}
                    label={translate(`resources.${resource}${key}`)}
                    value={getDefaultValue(field.value)}
                    onChange={onChange}
                    views={['year', 'month', 'day']}
                    slotProps={{
                        textField: {
                            helperText: isEmptyObject(formState.errors[source]) ? ' ' : translate(getKey(errorObject?.message)),
                            variant: !isEmptyObject(setFilters) ? 'outlined' : 'filled',

                        }
                    }}
                />
            </span>
        </Box>
    );
};


export default DatePickerFormatAble