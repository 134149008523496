import * as React from 'react';
import {
    List,
    Count,
    DatagridConfigurable,
    TextField,
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton, useTranslate, SelectArrayInput, BulkDeleteButton
} from 'react-admin';

import {useMediaQuery, Divider, Tabs, Tab, Theme} from '@mui/material';

import {Fragment, useCallback} from 'react';
import CustomDateField from "../fields/CustomDateField";
import SearchWithButton from "../fields/SearchWithButton";
import EntityMobileList from "./MobileListItem";
import {ListProps} from "../fields/RowStyle";
import {ROLES, LANGS} from "./Edit";

const publisherFilter = [
    <SearchWithButton source="email_contains" alwaysOn/>,
    <SelectArrayInput
        alwaysOn
        fullWidth
        variant="outlined"
        source="authorities_in"
        choices={ROLES}
    />
];

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
);

const EntityList = () => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    const translate = useTranslate();
    return (
        <List
            filterDefaultValues={{activated_equals: 'true'}}
            sort={{field: 'id', order: 'DESC'}}
            perPage={25}
            filters={publisherFilter}
            actions={<ListActions/>}
            title={translate('resources.publishers.name')}
        >
            <TabbedDataGrid isXSmall={isXSmall}/>
        </List>
    );
}

const tabs = [
    {id: 'true', name: 'Activate'},
    {id: 'false', name: 'Deactivate'},
];

const TabbedDataGrid = (props: ListProps) => {
        const {isXSmall} = props;
        const listContext = useListContext();
        const {filterValues, setFilters, displayedFilters} = listContext;

        const handleChange = useCallback(
            (event: React.ChangeEvent<{}>, value: any) => {
                setFilters &&
                setFilters(
                    {...filterValues, activated_equals: value},
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
            },
            [displayedFilters, filterValues, setFilters]
        );

        return (
            <Fragment>
                <Tabs
                    variant="fullWidth"
                    centered
                    value={filterValues.activated_equals}
                    indicatorColor="primary"
                    onChange={handleChange}
                >
                    {tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={
                                <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        'activated_equals': choice.id,
                                    }}
                                    sx={{lineHeight: 'inherit'}}
                                />
                                )
                            </span>
                            }
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider/>
                {isXSmall ? (
                    <EntityMobileList/>
                ) : (
                    <>
                        {filterValues.activated_equals === 'true' && (
                            <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
                                <TextField source="login"/>
                                <TextField source="email"/>
                                <TextField source="authorities" sortable={false}/>
                                <TextField source="createdBy"/>
                                <TextField source="langKey"/>
                                <CustomDateField source="createdDate"
                                                 format="DD/MM/YYYY"/>
                            </DatagridConfigurable>
                        )}
                    </>
                )}
            </Fragment>
        );
    }
;

export default EntityList;
