import WebsiteIcon from '@mui/icons-material/Web';

import List from './List';
import Create from './Create'

export default {
    list: List,
    create: Create,
    icon: WebsiteIcon,
};
