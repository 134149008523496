import {CommonInputProps, useInput, useRecordContext} from "react-admin";
import {isEmptyObject, isEmptyString} from "./util";
import {Fab} from "@mui/material";

import NavigationIcon from '@mui/icons-material/Navigation';

interface Props extends CommonInputProps {
    defaultValue?: any
    className?: any
    name?: string
    sortable?: boolean,
    rate?: any,
    onClick?: any
}

const FabRateField = (props: Props) => {
    const {source, defaultValue, className, onClick} = props
    const {field, fieldState} = useInput({source} as any);
    const data = isEmptyString(defaultValue) ? field.value : defaultValue;
    return (<Fab
        onClick={onClick}
        sx={{mr: 1, mb: 2}}
        className={className}
        variant="extended"
        size="small">
        <NavigationIcon/>
        {data}
    </Fab>);
}

export default FabRateField