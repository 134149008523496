import * as React from 'react';
import {
    Edit,
    TextInput,
    useTranslate,
    Form, useRecordContext, SelectInput, ReferenceInput, AutocompleteInput, ReferenceManyField, AutocompleteArrayInput
} from 'react-admin';
import {Card, CardContent, Box, Grid, Typography, Link} from '@mui/material';


import {Spacer} from "../fields/Space";
import {isEmptyObject, StatusChoices} from "../fields/util";
import DfpField from "./DfpField";
import Aside from "./Aside";
import {useEffect, useMemo, useState} from "react";

import './style.css';
import {EditToolbar} from "../fields/EditWithToolbar";

export const EntityTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();
    return (<span>
        {isEmptyObject(record?.id) ? translate('resources.adunits.page.create') : `${translate('resources.adunits.name')}: ${record.display}`}
    </span>)
}

export const transformAdvertiserData = (data: any) => {
    return {
        ...data,
        path: isEmptyObject(data.extra) ? data.path : JSON.stringify(data.extra),
        sources: isEmptyObject(data?.source) ? [] : data?.source?.map((sourceId: any) => {
            return {id: sourceId};
        })
    };
}

interface AdvertiserFormProps {
    state?: any;
    setState?: any;
}

const EntityEdit = () => {
    const [localState, setLocalState] = useState({
        dfps: [],
        website: 0,
        sources: [],
        addNew: null,
        movedDfps: []
    });

    return (
        <Edit aside={<Aside state={localState} setState={setLocalState}/>} title={<EntityTitle/>} redirect={"show"}
              component="div"
              transform={transformAdvertiserData}>
            <AdUnitForm state={localState} setState={setLocalState}/>
        </Edit>
    )
};

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.display) {
        errors.display = 'ra.validation.required';
    }

    if (!values.status) {
        errors.status = 'ra.validation.required';
    }
    return errors;
};


export const AdUnitForm = (props: AdvertiserFormProps) => {
    const {state: localState, setState: setLocalState} = props;
    const translate = useTranslate();
    const record = useRecordContext();
    const recordFix = useMemo(() => {
        return {...record, source: record?.sources?.map((source: any) => source.id)};
    }, [record])

    useEffect(() => {
        setLocalState({
            ...localState,
            website: record?.website?.id,
            sources: record?.sources,
        })
    }, [record])

    return (
        <Box maxWidth="70em">
            <Form validate={validateForm} className={"formxxx"} record={recordFix}>
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={8}>

                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.adunits.fieldGroups.info'
                                    )}
                                </Typography>

                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput fullWidth source="display" name={'display'}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ReferenceInput source="website.id" reference="websites">
                                        <AutocompleteInput fullWidth
                                                           optionText={(choice?: any) =>
                                                               choice?.id // the empty choice is { id: '' }
                                                                   ? `${choice.domain}`
                                                                   : ''
                                                           }
                                                           filterToQuery={(s) => {
                                                               return {domain_contains: s};
                                                           }}
                                                           onChange={(evt) => setLocalState({
                                                               ...localState,
                                                               website: evt
                                                           })}
                                        />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ReferenceInput fullWidth source="source" reference="sources">
                                        <AutocompleteArrayInput
                                            optionText={(choice?: any) =>
                                                choice?.id // the empty choice is { id: '' }
                                                    ? `${choice.name}`
                                                    : ''
                                            }
                                            filterToQuery={(s) => {
                                                return {
                                                    name_contains: s,
                                                    orIds: localState?.sources?.join(",")
                                                };
                                            }}
                                            onChange={(evt) => setLocalState({
                                                ...localState,
                                                sources: evt
                                            })}
                                        />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>

                                <Typography variant="h6" gutterBottom>
                                    <span dangerouslySetInnerHTML={{__html: '&nbsp;'}}/>
                                </Typography>
                                <Grid item xs={12} sm={12} md={12}>
                                    <SelectInput
                                        isRequired
                                        source="status"
                                        choices={StatusChoices}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Spacer/>

                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.adunits.fieldGroups.config'
                            )}
                        </Typography>
                        <DfpField sx={{minWidth: '100%'}}
                                  onDelete={(dfp: any) => {
                                      setLocalState({
                                          ...localState,
                                          dfps: [...localState?.dfps, dfp],
                                      })
                                  }}
                                  state={localState}/>
                        <Spacer/>
                    </CardContent>
                    <EditToolbar field={"display"}/>
                </Card>
            </Form>
        </Box>
    );
};


export default EntityEdit;
