import 'proxy-polyfill';

import * as React from 'react';

import {createRoot} from 'react-dom/client';

import App from './App';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const ele = document.getElementById('root');
if (ele !== null) {
    const root = createRoot(ele);

    root.render(
        <React.StrictMode>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <App/>
            </LocalizationProvider>
        </React.StrictMode>
    );
}

