import React, {CSSProperties, useMemo} from 'react';
import {Box, Card, CardContent, CardHeader, Theme, useMediaQuery} from '@mui/material';

import Welcome from './Welcome';
import CardIcon from "./CardIcon";
import {Spacer} from "../fields/Space";
import DollarIcon from '@mui/icons-material/AttachMoney';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import {useGetList, useTranslate, Loading, useLocales, useLocaleState, useGetIdentity} from "react-admin";
import ReactApexChart from "react-apexcharts";
import {apexDefaultOptions, apexDefaultPieOptions} from "./apex";
import {NO_FIXED_FORMAT_OPTIONS} from "../fields/MonyField";
import {ResponsiveContainer} from "recharts";
import {getRate} from "../reports/List";
import {isEmptyArray, isEmptyObject} from "../fields/util";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 2, marginRight: '0.5em'},
    rightCol: {flex: 1, marginLeft: '0.5em'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};


const processWebsitePie = (data: any, formatter: any, options: any, moneyRate: number = 1) => {
    const optionsPie: any = {...apexDefaultPieOptions};
    if (typeof data === "undefined" || data.length != 1) {
        return {options: optionsPie, series: [], labels: [], total: 0}
    }
    const websiteReport = data[0].pie;
    const length = Math.min(websiteReport.series.length, 5);
    const series = [...websiteReport.series.slice(0, length), websiteReport.series.slice(length).reduce((ac: any, a: any) => ac + a, 0)];
    optionsPie.labels = [...websiteReport.labels.slice(0, length), "Others"];

    optionsPie.tooltip.y.formatter = (val: any) => formatter.format(val * moneyRate);
    websiteReport.colors.push(...options.colors);
    optionsPie.colors = websiteReport.colors;

    return {
        options: optionsPie,
        series: series,
        labels: optionsPie.labels,
        total: series.reduce((ac, a) => ac + a, 0)
    }
}

export const AD_EXCHANGE_AD_REQUESTS = "Column.AD_EXCHANGE_TOTAL_REQUESTS";
export const AD_EXCHANGE_CLICKS = "Column.AD_EXCHANGE_LINE_ITEM_LEVEL_CLICKS";

export const AD_EXCHANGE_IMPRESSIONS = "Column.AD_EXCHANGE_LINE_ITEM_LEVEL_IMPRESSIONS";
export const AD_EXCHANGE_REVENUE = "Column.TOTAL_LINE_ITEM_LEVEL_ALL_REVENUE";

const processAreaSeries = (translate: any, data: any, options: any, formatter: any) => {
    if (typeof data === "undefined" || data.length != 1) {
        return {options: options, series: []}
    }
    const mainReport = data[0].data;
    const series = mainReport.series.map((s: any) => {
        if (isEmptyArray(s.data)) {
            return s;
        }
        s.data = s.data.map((d: any) => [d[0], parseFloat(d[1]).toFixed(2)]);
        return s;
    });

    const areaSeries = series.length > 0
        ? series
            .filter((s: any) => {
                return (
                    s.name === AD_EXCHANGE_AD_REQUESTS ||
                    s.name === AD_EXCHANGE_CLICKS ||
                    s.name === AD_EXCHANGE_IMPRESSIONS
                );
            })
            .map((s: any) => {
                return {
                    name: translate(`pos.dashboard.${s.name}`),
                    data: s.data,
                    type: s.name === AD_EXCHANGE_CLICKS ? 'line' : 'area',
                    color: s.color,
                };
            })
        : []

    if (series.length > 0) {
        const globalMax = Math.max(
            ...areaSeries
                .filter((l: any) => l.name !== translate(`pos.dashboard.${AD_EXCHANGE_CLICKS}`))
                .map((l: any) => {
                    return Math.max(...l.data.map((d: any) => d[1]));
                })
        );
        options.yaxis = areaSeries.map((l: any) => {
            const opposite = l.name === translate(`pos.dashboard.${AD_EXCHANGE_CLICKS}`);
            const show = l.name !== translate(`pos.dashboard.${AD_EXCHANGE_IMPRESSIONS}`);
            const max = opposite ? Math.max(...l.data.map((d: any) => d[1])) : globalMax;
            const margin = Math.floor((max * 10) / 100);

            return {
                labels: {
                    formatter(val: any) {
                        return formatter.format(val);
                    },
                },
                opposite,
                show,
                max: max + margin + 100 - ((max + margin) % 100),
            };
        });
    }
    return {
        options: options, series: areaSeries
    }
}


const Dashboard = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );
    const translate = useTranslate();
    const {identity, isLoading: identityLoading} = useGetIdentity();
    const isAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA');
    const [locale, _] = useLocaleState();

    const langKey = isAdmin ? locale : identity?.langKey;

    // sites=ab&cacheBuster=1692156094223&groupBy=date_only
    const {
        data: websiteRevenue,
        isLoading: websiteRevenueLoading
    } = useGetList<any>('report-ad-exchanges/report-by-website', {
        filter: {groupBy: 'date_only', ra: "1"},
        sort: {field: 'id', order: 'DESC'},
        pagination: {page: 0, perPage: 50},
    });

    // sites=ab&cacheBuster=1692156094223&groupBy=date_only
    const {
        data: websiteRequest,
        isLoading: websiteRequestLoading
    } = useGetList<any>('report-ad-exchanges/report-by-website', {
        filter: {groupBy: 'date_only', ra: "2"},
        sort: {field: 'id', order: 'DESC'},
        pagination: {page: 0, perPage: 50},
    });

    // sites=ab&cacheBuster=1692156094223&groupBy=date_only
    const {
        data: main,
        isLoading: mainLoading
    } = useGetList<any>('report-ad-exchanges/report-by-day', {
        filter: {groupBy: 'date_only', ra: "1"},
        sort: {field: 'id', order: 'DESC'},
        pagination: {page: 0, perPage: 50},
    });

    const {data: localeData} = useGetList("configs", {
        pagination: {page: 1, perPage: 10},
        sort: {field: 'id', order: "DESC"},
        filter: {name_equals: langKey}
    });

    const formatter = new Intl.NumberFormat(langKey, NO_FIXED_FORMAT_OPTIONS);
    const moneyFormatter = new Intl.NumberFormat(langKey, NO_FIXED_FORMAT_OPTIONS);
    const rate = getRate(JSON.parse((typeof localeData === "undefined" || isEmptyArray(localeData) || isEmptyObject(localeData[0])) ? "{}" : localeData[0].value));
    const optionsString = JSON.stringify(apexDefaultOptions);
    const options = JSON.parse(optionsString);

    const pieRevenue = useMemo(() => {
        return processWebsitePie(websiteRevenue, moneyFormatter, options, rate);
    }, [websiteRevenue]);

    const pieRequest = useMemo(() => {
        return processWebsitePie(websiteRequest, formatter, options);
    }, [websiteRequest]);

    const area = useMemo(() => {
        return processAreaSeries(translate, main, options, formatter)
    }, [main]);


    if (websiteRevenueLoading || websiteRequestLoading || mainLoading) {
        return <Loading/>
    }

    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <Welcome/>

                <CardIcon isMoney={true} lang={langKey} moneyRate={rate} icon={DollarIcon}
                          title={translate('pos.dashboard.monthly_revenue')}
                          subtitle={pieRevenue.total}
                          to={""}/>
                <Spacer/>
                <CardIcon icon={NotificationAddOutlinedIcon} title={translate('pos.dashboard.monthly_requests')}
                          subtitle={pieRequest.total}
                          to={""}/>

                <Card>
                    <CardHeader titleTypographyProps={{variant: 'h6'}}
                                title={translate('pos.dashboard.website_revenue')}/>
                    <CardContent>
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <ReactApexChart options={pieRevenue.options} series={pieRevenue.series}
                                                labels={pieRevenue.labels} type="pie"/>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                <Welcome/>
            </div>
            <div style={styles.flex}>
                <CardIcon isMoney={true} lang={langKey} moneyRate={rate} icon={DollarIcon}
                          title={translate('pos.dashboard.monthly_revenue')}
                          subtitle={pieRevenue.total}
                          to={""}/>
                <Spacer/>
                <CardIcon icon={NotificationAddOutlinedIcon} title={translate('pos.dashboard.monthly_requests')}
                          subtitle={pieRequest.total}
                          to={""}/>
            </div>
            <div style={styles.singleCol}>
                <Card>
                    <CardHeader titleTypographyProps={{variant: 'h6'}}
                                title={translate('pos.dashboard.website_revenue')}/>
                    <CardContent>
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <ReactApexChart options={pieRevenue.options} series={pieRevenue.series}
                                                labels={pieRevenue.labels} type="pie"/>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
                <Spacer/>

                <Card sx={{height: 630}}>
                    <CardHeader titleTypographyProps={{variant: 'h6'}}
                                title={translate('pos.dashboard.area')}/>
                    <CardContent>
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <ReactApexChart options={area.options} series={area.series} type="area"
                                                width="100%" height={300}/>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    ) : (
        <>
            <Welcome/>
            <div style={{...styles.flex, marginTop: '-130px'}}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <CardIcon isMoney={true} lang={langKey} moneyRate={rate} icon={DollarIcon}
                                  title={translate('pos.dashboard.monthly_revenue')}
                                  subtitle={pieRevenue.total}
                                  to={""}/>
                        <Spacer/>
                        <CardIcon icon={NotificationAddOutlinedIcon} title={translate('pos.dashboard.monthly_requests')}
                                  subtitle={pieRequest.total}
                                  to={""}/>
                    </div>
                    <div style={styles.singleCol}>
                        <Card sx={{height: 630}}>
                            <CardHeader titleTypographyProps={{variant: 'h6'}}
                                        title={translate('pos.dashboard.area')}/>
                            <CardContent>
                                <div style={{width: '100%', height: 300}}>
                                    <ResponsiveContainer>
                                        <ReactApexChart options={area.options} series={area.series} type="area"
                                                        width="100%" height={300}/>
                                    </ResponsiveContainer>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <Card>
                        <CardHeader titleTypographyProps={{variant: 'h6'}}
                                    title={translate('pos.dashboard.website_revenue')}/>
                        <CardContent>
                            <div style={{width: '100%', height: 250}}>
                                <ResponsiveContainer>
                                    <ReactApexChart options={pieRevenue.options} series={pieRevenue.series}
                                                    labels={pieRevenue.labels} type="pie"/>
                                </ResponsiveContainer>
                            </div>
                        </CardContent>
                    </Card>

                    <Box>&nbsp;</Box>

                    <Card>
                        <CardHeader titleTypographyProps={{variant: 'h6'}}
                                    title={translate('pos.dashboard.website_request')}/>
                        <CardContent>
                            <div style={{width: '100%', height: 250}}>
                                <ResponsiveContainer>
                                    <ReactApexChart options={pieRequest.options} series={pieRequest.series}
                                                    labels={pieRequest.labels} type="pie"/>
                                </ResponsiveContainer>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>

            <Spacer/>
            <Spacer/>
        </>
    );
};

export default Dashboard;
