import * as React from 'react';
import {FormDataConsumer, TextInput, TextField, useTranslate, SelectArrayInput} from 'react-admin';
import {get} from 'lodash';
import CreateIp from "./CreateIp";
import {useEffect, useState} from "react";
import {isEmptyArray} from "./util";

interface Props {
    source: string
    sx?: any
}

export const TARGET_TYPE_DETAIL = [
    {id: 'Linux', name: 'Linux', type: 'OS'},
    {id: 'Window', name: 'Window', type: 'OS'},
    {id: 'Macos', name: 'Macos', type: 'OS'},
    {id: 'HN', name: 'Hà Nội', type: 'Location'},
    {id: 'HCM', name: 'Hồ chí Minh', type: 'Location'},
    {id: 'VN', name: 'Việt Nam', type: 'Location'},
    {id: 'Computer', name: 'Computer/ Laptop', type: 'Device'},
    {id: 'Tablet', name: 'Tablet', type: 'Device'},
    {id: 'Phone', name: 'Phone', type: 'Device'},
]

const CodeTargetField = (props: Props) => {
    const {source, ...rest} = props;
    const [localState, setLocalState] = useState(TARGET_TYPE_DETAIL);

    return (
        <FormDataConsumer>
            {({formData, ...rest}) => {
                const currentType = get(formData, source.replaceAll(".values", ".target"), "");
                useEffect(() => {
                    const data = get(formData, source, []);
                    const oldTargeted = (isEmptyArray(data) ? [] : data).map((f:any) => {
                        return {id: f, name: f, type: currentType};
                    })
                    setLocalState([...localState, ...oldTargeted])
                }, [])
                const selectAbleTarget = localState.filter(t => t.type === currentType)
                const createElement = !["OS", "Location", "Device"].includes(currentType) ?
                    <CreateIp currentType={currentType} addChoice={(ch: any) => {
                        setLocalState([...localState, ch])
                    }
                    }/> : null as any
                return <SelectArrayInput isRequired
                                         source={source}
                                         choices={selectAbleTarget}
                                         create={createElement}
                />
            }}
        </FormDataConsumer>
    );
};


export default CodeTargetField