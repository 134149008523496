import * as React from 'react';
import {ReferenceField, TextField, ReferenceFieldProps} from 'react-admin';

const AdvertiserReferenceField = (
    props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
        source?: string;
    }
) => (
    <ReferenceField source="source.id" reference="sources" {...props}>
        <TextField source={"name"}/>
    </ReferenceField>
);

AdvertiserReferenceField.defaultProps = {
    source: 'source.id'
};

export default AdvertiserReferenceField;