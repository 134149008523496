import {CommonInputProps, useRecordContext} from "react-admin";
import {isEmptyObject} from "./util";
import {FIXED_FORMAT_OPTIONS, NO_FIXED_FORMAT_OPTIONS} from "./MonyField";

interface Props extends CommonInputProps {
    name?: string
    rate: number
    lang: string
    sortable?: boolean,
    value?: any,
    revCol: string
}

const ECPM = (props: Props) => {
    const {source, rate, lang, value, revCol} = props
    const record = useRecordContext<any>();
    const formatter = new Intl.NumberFormat(lang, lang == "vi" ? NO_FIXED_FORMAT_OPTIONS : FIXED_FORMAT_OPTIONS);
    if (isEmptyObject(record) && isEmptyObject(value)) {
        return null;
    }
    const estimatedRevenue = !isEmptyObject(record?.[revCol]) ? record[revCol] : 0;
    const impressions = !isEmptyObject(record?.["impressions"]) ? record["impressions"] : 0;
    if (impressions == 0) {
        return 0;
    }

    return (<span>
        {formatter.format(estimatedRevenue * rate * 1000 / impressions)}
    </span>)
}

export default ECPM