import * as React from 'react';
import {SVGProps} from 'react';
import {useTheme} from '@mui/material/styles';


const Logo = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        <>
            {theme?.palette?.mode !== 'dark' &&
            <img style={{maxHeight: 32}} src="https://z-holdings.vn/themes/zmedia/assets/images/z-holding-logo.png"/>}
            {theme?.palette?.mode === 'dark' &&
            <img style={{maxHeight: 32}} src="https://z-holdings.vn/themes/zmedia/assets/images/z-holding-logo-white.png"/>}
        </>
    );
};

export default Logo;
