import * as React from 'react';
import {Box, Button, Card, CardActions, Typography} from '@mui/material';
import {useStore, useTranslate} from 'react-admin';
import HomeIcon from '@mui/icons-material/Home';

const Welcome = () => {
    const translate = useTranslate();
    return (
        <Card
            sx={{
                background: theme =>
                    theme.palette.mode === 'dark'
                        ? '#535353'
                        : `linear-gradient(293deg, #3ac5f3 0%, #6ed8fb 100%)`,

                color: '#fff',
                padding: '20px',
                marginTop: 2,
                marginBottom: '1em',
            }}
        >
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        {translate('pos.dashboard.welcome.title')}
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                            {translate('pos.dashboard.welcome.subtitle')}
                        </Typography>
                    </Box>
                    <CardActions
                        sx={{
                            padding: {xs: 0, xl: null},
                            flexWrap: {xs: 'wrap', xl: null},
                            marginTop: {xs: '3em', xl: null},
                            '& a': {
                                marginLeft: {xs: '0!important', xl: null},
                                marginRight: {xs: '1em', xl: null},
                            },
                        }}
                    >
                        <Button
                            variant="contained"
                            href="https://z-holdings.vn/"
                            startIcon={<HomeIcon/>}
                        >
                            {translate('pos.dashboard.welcome.ra_button')}
                        </Button>
                    </CardActions>
                </Box>
                <Box
                    display={{xs: 'none', sm: 'none', md: 'block'}}
                    sx={{
                        background: `url('https://zmedia.vn/themes/zmedia/assets/images/our-products.png') top right / cover`,
                        marginLeft: 'auto',
                    }}
                    width="16em"
                    height="18em"
                    overflow="hidden"
                />
            </Box>
        </Card>
    );
};

export default Welcome;
