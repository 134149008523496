import * as React from 'react';
import {
    Edit,
    TextInput,
    useTranslate,
    Form,
    useRecordContext,
    SelectInput,
    ReferenceInput,
    AutocompleteInput, isEmpty,
} from 'react-admin';
import {Card, CardContent, Box, Grid, Typography, Link} from '@mui/material';


import {Spacer} from "../fields/Space";
import {isEmptyArray, isEmptyObject, isEmptyString, LogoChoices, StatusChoices} from "../fields/util";
import Aside from "./Aside";
import {useEffect, useMemo, useState} from "react";

import './style.css';
import {EditToolbar} from "../fields/EditWithToolbar";
import ReadOnlyCopyableField from "../fields/ReadOnlyCopyableField";
import CodeLayer from "./CodeLayer";

export const EntityTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();
    return (<span>
        {isEmptyObject(record?.id) ? translate('resources.adunits.page.create') : `${translate('resources.adunits.name')}: ${record.display}`}
    </span>)
}

export const transformAdvertiserData = (data: any) => {
    const newLayers = data.layers.map((l: any) => {
        const result = {id: l.idx, idx: 0, ...l} as any;
        delete result.idx;
        return result;
    });
    if (!isEmptyObject(data.website) && isEmptyObject(data.website.id)) {
        delete data.website;
    }
    return {
        ...data,
        code: JSON.stringify({layers: newLayers.filter((l: any) => !isEmptyObject(l.id) && !l.deleted)}),
        config: isEmptyObject(data.configJS) ? null : JSON.stringify(data.configJS)
    };
}

interface AdvertiserFormProps {
    state?: any;
    setState?: any;
}

const parseExtra = (record: any) => {
    return (isEmptyObject(record) || isEmptyObject(record.code)) ? {layers: []} : JSON.parse(record.code);
}

const EntityEdit = () => {
    const [localState, setLocalState] = useState({
        show: 'show'
    });

    return (
        <Edit aside={<Aside state={localState} setState={setLocalState}/>} title={<EntityTitle/>} redirect={"show"}
              component="div"
              transform={transformAdvertiserData}>
            <EmbedCodeForm state={localState} setState={setLocalState}/>
        </Edit>
    )
};

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    return errors;
};

export const EmbedCodeForm = (props: AdvertiserFormProps) => {
    const {state: localState, setState: setLocalState} = props;
    const translate = useTranslate();
    const record = useRecordContext();
    const parsedLayer = parseExtra(record).layers.map((l: any) => {
        const result = {idx: l.id, id: 0, deleted: false, ...l} as any;
        delete result.id;
        return result;
    });

    const recordFix = useMemo(() => {
        return {
            ...record,
            layers: (isEmptyArray(parsedLayer) || typeof parsedLayer === "string") ? [] : parsedLayer,
            configJS: (isEmptyObject(record) || isEmptyString(record.config)) ? {} : JSON.parse(record.config)
        };
    }, [record])

    useEffect(() => {
        setLocalState({
            ...localState,
            id: record?.id,
        })
    }, [record])
    return (
        <Box maxWidth="70em">
            <Form validate={validateForm} className={"formxxx"} record={recordFix}>
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={8}>

                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.adunits.fieldGroups.info'
                                    )}
                                </Typography>

                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput fullWidth source="name" name={'name'}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ReferenceInput source="website.id" reference="websites">
                                        <AutocompleteInput fullWidth
                                                           optionText={(choice?: any) =>
                                                               choice?.id // the empty choice is { id: '' }
                                                                   ? `${choice.domain}`
                                                                   : ''
                                                           }
                                                           filterToQuery={(s) => {
                                                               return {domain_contains: s};
                                                           }}
                                                           onChange={(evt) => setLocalState({
                                                               ...localState,
                                                               website: evt
                                                           })}
                                        />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Typography variant="h6" gutterBottom>
                                    <span dangerouslySetInnerHTML={{__html: '&nbsp;'}}/>
                                </Typography>
                                <Grid item xs={12} sm={12} md={12}>
                                    <SelectInput isRequired
                                                 source="status"
                                                 choices={StatusChoices}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ReadOnlyCopyableField name={"html"} source={"html"}/>
                        </Grid>
                        <Spacer/>

                        <Grid item xs={12} sm={12} md={12}>
                            <SelectInput
                                source="configJS.logo.value"
                                choices={LogoChoices}
                            />
                        </Grid>
                        <CodeLayer name={"layers"} source={"layers"}/>
                    </CardContent>
                    <EditToolbar field={"display"}/>
                </Card>
            </Form>
        </Box>
    );
};


export default EntityEdit;
