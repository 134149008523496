import {AuthProvider} from 'react-admin';
import {isEmptyString} from "./fields/util";

const authProvider: AuthProvider = {
    login: ({username, password}) => {
        const request = new Request('/api/authenticate', {
            method: 'POST',
            body: JSON.stringify({username, password, rememberMe: false}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', auth['id_token']);
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => {
        const request = new Request('/api/account', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('auth')}`
            }),
        });

        return fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        }).then(user => {
            return user.authorities;
        })
    },
    getIdentity: () => {
        const request = new Request('/api/account', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('auth')}`
            }),
        });
        return fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        }).then(user => {
            return {
                id: user.id,
                fullName: `${isEmptyString(user.lastName) ? user.firstName : user.lastName}`,
                authorities: user.authorities,
                langKey: user.langKey
            };
        })
    }
};

export default authProvider;
