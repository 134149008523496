import {Create} from "react-admin";
import * as React from "react";
import {EntityTitle, PublisherForm} from "./Edit";

const EntityCreate = () => (
    <Create title={<EntityTitle/>} component="div">
        <PublisherForm/>
    </Create>
);

export default EntityCreate;