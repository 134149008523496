import {CommonInputProps, useRecordContext} from "react-admin";
import {isEmptyObject} from "./util";
import {NO_FIXED_FORMAT_OPTIONS} from "./MonyField";

interface Props extends CommonInputProps {
    name?: string
    lang: string,
    sortable?: boolean,
    rate?: any
}

const LongField = (props: Props) => {
    const {source, lang} = props
    const record = useRecordContext<any>();
    const formatter = new Intl.NumberFormat(lang, NO_FIXED_FORMAT_OPTIONS);
    if (!record) {
        return null;
    }
    const data = record[source];

    if (isEmptyObject(data)) {
        return null;
    }

    return (<span>
        {formatter.format(data)}
    </span>)
}

export default LongField