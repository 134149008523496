import {HtmlHTMLAttributes, useCallback, useEffect, useRef, useState} from "react";
import ContentFilter from "@mui/icons-material/CloudSync";
import * as React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
    styled, useMediaQuery, useTheme
} from "@mui/material";
import {fetchJson} from "../App";
import {useListContext, Loading} from "react-admin";
import {isEmptyObject, isEmptyString} from "../fields/util";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";


interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
    className?: string,
    setLoading?: any,
}

const SyncAction = (props: Props) => {
    const {className, setLoading} = props;
    const [localState, setLocalState] = useState({
        main: false,
        dialog: false,
        alert: false,
        startDate: null,
        endDate: null,
        loading: -1,
    });
    const anchorEl = useRef();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {
        filterValues,
        displayedFilters,
        setFilters
    } = useListContext();


    const handleClickButton = useCallback(
        (event: any) => {
            // This prevents ghost click.
            event.preventDefault();
            setLocalState({...localState, main: true});
            anchorEl.current = event.currentTarget;
        },
        [anchorEl, setLocalState]
    );

    const handleRequestClose = useCallback(() => {
        setLocalState({...localState, main: false});
    }, [setLocalState]);

    const handleClose = useCallback(() => {
        setLocalState({...localState, dialog: false, loading: -1});
    }, [setLocalState]);

    const handleCloseAlert = useCallback(() => {
        setLocalState({...localState, alert: false, dialog: false});
    }, [setLocalState]);


    const handleClickOpen = useCallback(() => {
        setLocalState({...localState, dialog: true});
    }, [setLocalState]);

    const sync = (data: any) => {
        const startDateFilter = isEmptyString(data?.startDate) ? '' : `&startDate=${data?.startDate}`;
        const endDateFilter = isEmptyString(data?.endDate) ? '' : `&endDate=${data?.endDate}`;
        const query = {} as any;
        Object.keys(filterValues).forEach(k => {
            query[k.replaceAll("_", ".")] = filterValues[k];
        })

        const otherParams = `&${decodeURIComponent(new URLSearchParams(query).toString())}`;
        setLocalState({...localState, loading: 0})
        fetchJson(`/api/sources?syncAdx=${data.type}${startDateFilter}${endDateFilter}${otherParams}`)
            .then(({
                       headers,
                       json
                   }) => {
                setLocalState({...localState, alert: true, startDate: null, endDate: null, loading: 1, dialog: false})
            })
    }

    const syncNewAdv = () => {
        sync({type: "0"})
    }

    const syncGoogleSheet = () => {
        sync({type: "2"})
    }

    const syncAdx = () => {
        sync({type: "3"})
    }
    const syncShopee = () => {
        sync({type: "6"})
    }
    const advanceSync = () => {
        sync({
            type: "1",
            startDate: localState?.startDate,
            endDate: localState?.endDate
        })
    }


    const getDefaultValue = (filterValue: any) => {
        return isEmptyString(filterValue) ? null : dayjs(filterValue, "YYYY-MM-DD");
    }

    const onChange = (evt: any, key: string) => {
        const curDate = dayjs(evt);
        if (curDate.isValid() && curDate.get('year') > 1970) {
            setLocalState({...localState, [key]: curDate.format("YYYY-MM-DD")})
        }
    }

    return <>
        <Root className={className}>
            <Button
                className="add-filter"
                aria-haspopup="true"
                size="small"
                color="primary"
                sx={{lineHeight: "1.5"}}
                onClick={handleClickButton}
            >
                <span className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon">
                    <ContentFilter/>&nbsp;Sync
                </span>
            </Button>
            <Menu
                open={localState.main}
                anchorEl={anchorEl.current}
                onClose={handleRequestClose}
            >
                <MenuItem>
                    <a onClick={syncNewAdv}>Sync new advertisers</a>
                </MenuItem>
                <MenuItem>
                    <a onClick={syncGoogleSheet}>Sync google sheet data</a>
                </MenuItem>
                <MenuItem>
                    <a onClick={syncAdx}>Sync adx, adsense, admob </a>
                </MenuItem>
                <MenuItem>
                    <a onClick={syncShopee}>Sync shopee </a>
                </MenuItem>
                <MenuItem>
                    <a onClick={handleClickOpen}>Advance sync</a>
                </MenuItem>
            </Menu>
            <Dialog
                open={localState.loading >= 0}
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Zmedia system"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {localState.loading !== 1 && <span>Running ...</span>}
                        {localState.loading === 1 && <span>Continue running in the backgrounds</span>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {localState.loading === 1 && <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>}
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen={fullScreen}
                open={localState.dialog}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Zmedia system"}
                </DialogTitle>
                <DialogContent>
                    <DatePicker
                        sx={{width: "100%"}}
                        format={"DD/MM/YYYY"}
                        label={`From time`}
                        defaultValue={getDefaultValue(localState.startDate)}
                        onChange={(evt: any) => onChange(evt, "startDate")}
                        views={['year', 'month', 'day']}
                        slotProps={{
                            textField: {
                                helperText: `start date`,
                                variant: !isEmptyObject(setFilters) ? 'outlined' : 'filled',
                            }
                        }}
                    />
                    <DatePicker
                        sx={{width: "100%"}}
                        format={"DD/MM/YYYY"}
                        label={'To time'}
                        defaultValue={getDefaultValue(localState.endDate)}
                        onChange={(evt: any) => onChange(evt, "endDate")}
                        views={['year', 'month', 'day']}
                        slotProps={{
                            textField: {
                                helperText: `end date`,
                                variant: !isEmptyObject(setFilters) ? 'outlined' : 'filled',
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Disagree
                    </Button>
                    <Button onClick={advanceSync} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Root>
    </>
}

const PREFIX = 'RaFilterButton';

const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})({
    display: 'inline-block',
});

export default SyncAction