import {DeleteWithConfirmButton, SaveButton, Toolbar, useRecordContext} from "react-admin";
import * as React from "react";
import {styled} from "@mui/material/styles";
import {Theme, Toolbar as MuiToolbar, useMediaQuery} from "@mui/material";
import {isEmptyObject} from "./util";

const PREFIX = 'RaToolbar';
export const ToolbarClasses = {
    desktopToolbar: `${PREFIX}-desktopToolbar`,
    mobileToolbar: `${PREFIX}-mobileToolbar`,
    defaultToolbar: `${PREFIX}-defaultToolbar`,
};

interface EditToolbarProps {
    field?: any
}

export const EditToolbar = (props: EditToolbarProps) => {
    const {field} = props;
    const record = useRecordContext();
    const isXs = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const getName = () => {
        if (isEmptyObject(record)) {
            return 'New';
        }
        return isEmptyObject(record.name) ? record[field] : record.name
    }
    return (<StyledToolbar
        role="toolbar"
        {...props}
    >
        <div className={ToolbarClasses.defaultToolbar}>
            <SaveButton/>
            {!isEmptyObject(record) && !isEmptyObject(record.id) && <DeleteWithConfirmButton
                confirmContent="You will not be able to recover this record. Are you sure?"
                translateOptions={{name: getName()}}
            />}
        </div>
    </StyledToolbar>)
};
const StyledToolbar = styled(MuiToolbar, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],

    [`&.${ToolbarClasses.desktopToolbar}`]: {},

    [`&.${ToolbarClasses.mobileToolbar}`]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '16px',
        width: '100%',
        boxSizing: 'border-box',
        flexShrink: 0,
        zIndex: 2,
    },

    [`& .${ToolbarClasses.defaultToolbar}`]: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));