import {CommonInputProps, useRecordContext} from "react-admin";
import {isEmptyObject} from "./util";

interface Props extends CommonInputProps {
    name?: string
    rate: number
    lang: string
    sortable?: boolean,
    value?: any
}

export const NO_FIXED_FORMAT_OPTIONS = {minimumFractionDigits: 0, maximumFractionDigits: 0};
export const FIXED_FORMAT_OPTIONS = {minimumFractionDigits: 2, maximumFractionDigits: 2};

import "./style.css";

const MoneyField = (props: Props) => {
    const {source, rate, lang, value} = props
    const record = useRecordContext<any>();
    const formatter = new Intl.NumberFormat(lang, lang == "vi" ? NO_FIXED_FORMAT_OPTIONS : FIXED_FORMAT_OPTIONS);
    if (isEmptyObject(record) && isEmptyObject(value)) {
        return null;
    }
    const data = !isEmptyObject(record?.[source]) ? record[source] : value;

    if (isEmptyObject(data)) {
        return null;
    }
    return (<span className={lang === "vi" ? "viMoney" : "enMoney"}>
        {formatter.format(data * rate)}
    </span>)
}

export default MoneyField