import {CommonInputProps, useInput, useRecordContext} from "react-admin";
import {isEmptyObject} from "./util";

interface Props extends CommonInputProps {
    max: number,
    name?:string
}

const LongTextField = (props: Props) => {
    const {source, max} = props
    const record = useRecordContext<any>();

    if (!record) {
        return null;
    }
    const data = record[source];

    if (isEmptyObject(data)) {
        return null;
    }

    return (<span>
        {data.length > max ? `${data.slice(0, max)}...` : data}
    </span>)
}

LongTextField.defaultProps = {
    max: 50
}

export default LongTextField