import * as React from 'react';
import {
    Children,
    cloneElement,
    MouseEvent,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Button,
    ButtonProps, Grid, SxProps,
    TableBody, TableCell, TableRow
} from '@mui/material';
import clsx from 'clsx';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {
    FormDataConsumer,
    RaRecord,
    useRecordContext,
} from 'ra-core';
import {UseFieldArrayReturn, useFormContext} from 'react-hook-form';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowCircleDown';
import CloseIcon from '@mui/icons-material/RemoveCircleOutline';

import {
    SimpleFormIteratorClasses,
    useArrayInput, useInput, useTranslate
} from 'react-admin';
import {TableFormIteratorItem} from "./TableFormIteratorItem";
import {useTableFormIteratorItem} from "./useTableFormIteratorItem";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import "./style.css";
import FabRateField from "./FabRateField";
import {TableFormIteratorContext} from "./TableFormIteratorContext";
import {useTableFormIterator} from "./useTableFormIterator";
import {isEmptyObject, isEmptyString} from "./util";

interface InputProps {
    props?: any
}

export const ClearArrayButton = (props: ButtonProps) => (
    <ArrowUpwardIcon fontSize="small"/>
);


export const ReOrderButtons = ({className}: { className?: string }) => {
    const {index, total, reOrder} = useTableFormIteratorItem();
    const definedIndex = typeof index === "undefined" ? -1 : index;
    return (
        <span className={className}>
            {definedIndex > 0
            && <ArrowUpwardIcon fontSize="small"
                                onClick={() => reOrder(definedIndex - 1)}/>}

            {!(total == null || definedIndex >= total - 1)
            && <ArrowDownwardIcon fontSize="small"
                                  onClick={() => reOrder(definedIndex + 1)}/>}
        </span>
    );
};

export const AddItemButton = (props: ButtonProps) => {
    const {add} = useTableFormIterator();
    return (
        <Button onClick={() => add()} variant="outlined" startIcon={<AddIcon fontSize="small"/>}>
            Add layer
        </Button>
    );
};


export const RemoveItemButton = (props: Omit<ButtonProps, 'onClick'>) => {
    const {remove} = useTableFormIteratorItem();
    return (
        <CloseIcon fontSize="small"
                   onClick={() => remove()}/>
    );
};


export const TableFormIterator = (props: TableFormIteratorProps) => {
    const {
        view,
        onRemove,
        onAdd,
        defaultValue: defVal,
        addButton = <AddItemButton/>,
        removeButton = <RemoveItemButton/>,
        reOrderButtons = <ReOrderButtons/>,
        children,
        resource,
        source,
        disabled,
        disableAdd = false,
        disableClear,
        disableRemove = false,
        disableReordering,
        inline,
        getItemLabel = false,
        sx,
    } = props;
    const [showItem, setShowItem] = useState<number>(0);
    const {append, fields, move, remove: arrayRemove, replace, update} = useArrayInput(props);
    const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
    const {resetField} = useFormContext();
    const translate = useTranslate();
    const record = useRecordContext(props);
    const initialDefaultValue = useRef({} as any);

    const getDeletedField = (field: any): any => {
        return {...field, "deleted": true}
    }
    const removeField = useCallback(
        (index: number) => {
            const field = fields[index];
            const newField = getDeletedField(field);
            update(index, newField);
            resetField(`${source}.${index}`, newField);
            onRemove(newField);
            setShowItem(-1)
        },
        [update, arrayRemove, resetField, onRemove]
    );
    if (fields.length > 0) {
        const {id, ...rest} = fields[0];
        initialDefaultValue.current = rest;
        for (const k in initialDefaultValue.current)
            initialDefaultValue.current[k] = null;
    }
    const addField = useCallback(
        (item: any = undefined) => {
            let defaultValue = item;
            if (item == null || typeof item == "undefined") {
                defaultValue = initialDefaultValue.current;
                if (
                    Children.count(children) === 1 &&
                    React.isValidElement(Children.only(children)) &&
                    // @ts-ignore
                    !Children.only(children).props.source &&
                    // Make sure it's not a FormDataConsumer
                    Children.map(
                        children,
                        (input: any) =>
                            React.isValidElement(input) &&
                            input.type !== FormDataConsumer
                    ).some(Boolean)
                ) {
                    // ArrayInput used for an array of scalar values
                    // (e.g. tags: ['foo', 'bar'])
                    defaultValue = '';
                } else {
                    // ArrayInput used for an array of objects
                    // (e.g. authors: [{ firstName: 'John', lastName: 'Doe' }, { firstName: 'Jane', lastName: 'Doe' }])
                    const maxID = Math.max(...fields.map((field: any) => parseInt(field.idx, 10) || 0));
                    const newID = (isEmptyObject(maxID) || isFinite(maxID) || maxID < 0) ? 1 : maxID + 1;
                    defaultValue = ({...defVal, id: newID, idx: newID} as Record<string, unknown>);
                }
            }
            append(defaultValue);
            // Make sure the newly added inputs are not considered dirty by react-hook-form
            resetField(`${source}.${fields.length}`, {defaultValue});
            onAdd(defaultValue);
            setShowItem(fields.length)
        },
        [append, children, resetField, source, fields.length, onAdd]
    );

    // add field and call the onClick event of the button passed as addButton prop
    const handleAddButtonClick = (
        originalOnClickHandler: MouseEventHandler
    ) => (event: MouseEvent) => {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    const handleReorder = useCallback(
        (origin: number, destination: number) => {
            move(origin, destination);
        },
        [move]
    );

    const handleArrayClear = useCallback(() => {
        replace([]);
        setConfirmIsOpen(false);
    }, [replace]);

    const records = get(record, source);

    const context = useMemo(
        () => ({
            total: fields.length,
            add: addField,
            remove: removeField,
            reOrder: handleReorder,
            source,
        }),
        [addField, fields.length, handleReorder, removeField, source]
    );

    const fieldMap = () => {
        return fields.map((member, index) => (
            <TableFormIteratorItem
                className={showItem !== index ? 'invisible' : ''}
                key={member.id}
                disabled={disabled}
                disableRemove={disableRemove}
                disableReordering={disableReordering}
                fields={fields}
                getItemLabel={getItemLabel}
                index={index}
                member={`${source}.${index}`}
                onRemoveField={removeField}
                onReorder={handleReorder}
                record={(records && records[index]) || {}}
                removeButton={removeButton}
                reOrderButtons={reOrderButtons}
                resource={resource}
                source={source}
                inline={inline}
            >
                {children}
            </TableFormIteratorItem>
        ))
    }

    return fields ? (
        <TableFormIteratorContext.Provider value={context}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={2}>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableBody>
                                {fields.map((row: any, index: any) => {
                                    const viewData = get(row, view, '')
                                    const idx = get(row, "idx", '')
                                    const deleted = get(row, "deleted", '')
                                    if (isEmptyString(idx) || (!isEmptyObject(deleted) && deleted)) {
                                        return null;
                                    }
                                    return <TableRow key={`row-${index}`}>
                                        <TableCell>
                                            <FabRateField className={showItem === index ? 'background-primary' : ''}
                                                          source={view} defaultValue={viewData}
                                                          onClick={() =>
                                                              setShowItem(index)}/>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableBody
                                sx={sx}
                            >
                                {!disabled && !(disableAdd && (disableClear || disableRemove)) && (
                                    <TableRow className={SimpleFormIteratorClasses.buttons}>
                                        {!disableAdd && (
                                            <TableCell className={SimpleFormIteratorClasses.add}>
                                                {cloneElement(addButton, {
                                                    className: clsx(
                                                        'button-add',
                                                        `button-add-${source}`
                                                    ),
                                                    onClick: handleAddButtonClick(
                                                        addButton.props.onClick
                                                    ),
                                                })}
                                                {fields.length > 0 && !disableClear && !disableRemove && (
                                                    <div className={SimpleFormIteratorClasses.clear}>

                                                        <ClearArrayButton
                                                            onClick={() => setConfirmIsOpen(true)}
                                                        />
                                                    </div>
                                                )}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                )}
                                {fieldMap()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        </TableFormIteratorContext.Provider>
    ) : null;
};

TableFormIterator.propTypes = {
    addButton: PropTypes.element,
    removeButton: PropTypes.element,
    children: PropTypes.node,
    className: PropTypes.string,
    field: PropTypes.object,
    fields: PropTypes.array,
    fieldState: PropTypes.object,
    formState: PropTypes.object,
    fullWidth: PropTypes.bool,
    inline: PropTypes.bool,
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    TransitionProps: PropTypes.shape({}),
};

type GetItemLabelFunc = (index: number) => string | ReactElement;

export interface TableFormIteratorProps extends Partial<UseFieldArrayReturn> {
    addButton?: ReactElement;
    onRemove?: any;
    onAdd?: any;
    defaultValue: any;
    view?: any;
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    disableAdd?: boolean;
    disableClear?: boolean;
    disableRemove?: any;
    disableReordering?: boolean;
    fullWidth?: boolean;
    getItemLabel?: boolean | GetItemLabelFunc;
    inline?: boolean;
    meta?: {
        // the type defined in FieldArrayRenderProps says error is boolean, which is wrong.
        error?: any;
        submitFailed?: boolean;
    };
    record?: RaRecord;
    removeButton?: ReactElement;
    reOrderButtons?: ReactElement;
    resource?: any;
    source?: any;
    sx?: SxProps;
}

