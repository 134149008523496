import * as React from 'react';
import {
    Edit,
    TextInput,
    useEditController,
    SimpleForm,
    useTranslate,
    useGetIdentity,
    useRedirect,
    SaveButton,
    useNotify
} from 'react-admin';
import {Box, Card, Toolbar, Typography} from "@mui/material";


const PostEditToolbar = () => {
    const redirect = useRedirect();
    const notify = useNotify();

    return (<Toolbar>
        <SaveButton
            mutationOptions={{
                onSuccess: data => {
                    notify('ra.notification.created', {
                        type: 'info',
                        messageArgs: {smart_count: 1},
                    });
                    redirect(false);
                }
            }}
            type={"button"}
        />
    </Toolbar>);
}


const FixDateReport = ({title = 'Config date'}) => {
    const {record, save: saveConfig, isLoading} = useEditController({resource: 'configs', id: 33444151});
    const {identity, isLoading: identityLoading} = useGetIdentity();
    const isSuperAdmin = identity?.authorities.includes('ROLE_ADMIN');


    const translate = useTranslate();
    if (isLoading || !isSuperAdmin) return null;
    const getSaveHandler = (): any => {
        return saveConfig;
    }
    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate(
                    'resources.configs.setting-date'
                )}
            </Typography>
            <Edit resource={"configs"} mutationMode="pessimistic">
                <Card>
                    <SimpleForm record={record} onSubmit={getSaveHandler()} toolbar={<PostEditToolbar/>}>
                        <TextInput source="name"/>
                        <TextInput source="value"/>
                    </SimpleForm>
                </Card>
            </Edit>
        </>
    );
};

export default FixDateReport;