import * as React from 'react';
import Box from '@mui/material/Box';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState, useGetIdentity,
} from 'react-admin';

import websites from '../websites';
import advertisers from '../advertisers';
import publishers from '../publishers';
import adunits from '../adunits';
import codes from '../code';
import report from '../reports';
import analytic from '../analytic';
import SettingsIcon from '@mui/icons-material/Settings';
import {useEffect, useState} from "react";
import {isEmptyObject} from "../fields/util";
import "./style.css";
import SubMenu from "./SubMenu";
import ReportIcon from '@mui/icons-material/Assessment';
import AnalyticsIcon from '@mui/icons-material/Analytics';

type MenuName = 'menuConfig';

const Menu = ({dense = false}: MenuProps) => {
        const [state, setState] = useState({
            menuConfig: true,
        });
        const [open] = useSidebarState();

        const handleToggle = (menu: MenuName) => {
            setState(state => ({...state, [menu]: !state[menu]}));
        };
        const translate = useTranslate();
        const {identity, isLoading: identityLoading} = useGetIdentity();

        useEffect(() => {
            if (isEmptyObject(localStorage.getItem("RaStore.sidebar.open"))) {
                localStorage.setItem("RaStore.sidebar.open", "true");
            }
        }, [])

        if (identityLoading) {
            return <></>
        }
        const isAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA');
        const analytic = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ANALYTIC');
        const isSuperAdmin = identity?.authorities.includes('ROLE_ADMIN');


        const customMenu = {};

        return (
            <Box
                sx={{
                    width: open ? 200 : 50,
                    marginTop: 1,
                    marginBottom: 1,
                    transition: theme =>
                        theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                }}
            >
                <DashboardMenuItem sx={customMenu}/>
                <MenuItemLink sx={customMenu}
                              to="/report-ad-exchanges/report"
                              state={{_scrollToTop: true}}
                              primaryText={translate(`resources.report.name`, {
                                  smart_count: 2,
                              })}
                              onClick={() => {
                                  localStorage.setItem("RaStore.report-ad-exchanges/report.listParams", "{}");
                              }}
                              leftIcon={<ReportIcon />}
                              dense={dense}
                />


                {analytic && <MenuItemLink sx={customMenu}
                                           to="/report-ad-exchanges/analytic"
                                           state={{_scrollToTop: true}}
                                           primaryText={translate(`resources.analytic.name`, {
                                               smart_count: 2,
                                           })}
                                           onClick={() => {
                                               localStorage.setItem("RaStore.report-ad-exchanges/analytic.listParams", "{}");
                                           }}
                                           leftIcon={<AnalyticsIcon />}
                                           dense={dense}
                />}
                <MenuItemLink sx={customMenu}
                              to="/admin/users"
                              state={{_scrollToTop: true}}
                              primaryText={translate(`resources.publishers.name`, {
                                  smart_count: 2,
                              })}
                              leftIcon={<publishers.icon/>}
                              dense={dense}
                />
                <MenuItemLink sx={customMenu}
                              to="/websites"
                              state={{_scrollToTop: true}}
                              primaryText={translate(`resources.websites.name`, {
                                  smart_count: 2,
                              })}
                              leftIcon={<websites.icon/>}
                              dense={dense}
                />
                {isAdmin && <MenuItemLink sx={customMenu}
                                          to="/sources"
                                          state={{_scrollToTop: true}}
                                          primaryText={translate(`resources.advertisers.name`, {
                                              smart_count: 2,
                                          })}
                                          leftIcon={<advertisers.icon/>}
                                          dense={dense}
                />}
                {isAdmin && <MenuItemLink sx={customMenu}
                                          to="/ad-units"
                                          state={{_scrollToTop: true}}
                                          primaryText={translate(`resources.adunits.name`, {
                                              smart_count: 2,
                                          })}
                                          leftIcon={<adunits.icon/>}
                                          dense={dense}
                />}
                {isAdmin && <MenuItemLink sx={customMenu}
                                          to="/codes"
                                          state={{_scrollToTop: true}}
                                          primaryText={translate(`resources.codes.name`, {
                                              smart_count: 2,
                                          })}
                                          leftIcon={<codes.icon/>}
                                          dense={dense}
                />}
                {isAdmin && <SubMenu
                  handleToggle={() => handleToggle('menuConfig')}
                  isOpen={state.menuConfig}
                  name="resources.configs.menu"
                  icon={<SettingsIcon/>}
                  dense={dense}
                >
                    {isAdmin && <MenuItemLink sx={customMenu}
                                              to="/settings-adsense"
                                              state={{_scrollToTop: true}}
                                              primaryText={translate(`resources.configs.setting-adsense`, {
                                                  smart_count: 2,
                                              })}
                                              dense={dense}/>}
                    {isSuperAdmin && <MenuItemLink sx={customMenu}
                                                   to="/settings-date/33444151"
                                                   state={{_scrollToTop: true}}
                                                   primaryText={translate(`resources.configs.setting-date`, {
                                                       smart_count: 2,
                                                   })}
                                                   dense={dense}/>}
                    {isAdmin && <MenuItemLink sx={customMenu}
                                              to="/settings-advertiser"
                                              state={{_scrollToTop: true}}
                                              primaryText={translate(`resources.configs.advertiser-factor`, {
                                                  smart_count: 2,
                                              })}
                                              dense={dense}/>}
                </SubMenu>}
            </Box>
        );
    }
;

export default Menu;
