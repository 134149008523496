import React from 'react';
import {useRecordContext} from 'react-admin';
import dayjs from "dayjs";

interface Props {
    source: string,
    format: string,
}

const CustomDateField = (props: Props) => {
    const {source, format} = props;
    const record = useRecordContext<any>();

    if (!record) {
        return null;
    }
    const date = record[source];

    if (!date) {
        return null;
    }

    const formattedDate = dayjs(date).format(format);

    return <span>{formattedDate}</span>;
};

export default CustomDateField;