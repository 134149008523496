import {useChoicesContext, AutocompleteInput} from "react-admin";
import {useEffect} from "react";


interface IAutocompleteInputWithContext {
    setChoices: any;
    fullWidth: any;
    optionText: any;
    filterToQuery: any;
    onChange: any;
}

export const AutocompleteInputWithContext = (props: IAutocompleteInputWithContext) => {
    const {setChoices, ...rest} = props
    const {allChoices} = useChoicesContext();
    useEffect(() => {
        props.setChoices(allChoices)
    }, [allChoices])
    return (
        <>
            <AutocompleteInput {...rest} />
        </>
    );
};