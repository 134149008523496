import PublisherIcon from '@mui/icons-material/AccountCircle';

import PublisherList from './List';
import PublisherEdit from './Edit';
import PublisherCreate from './Create';

export default {
    list: PublisherList,
    edit: PublisherEdit,
    create: PublisherCreate,
    icon: PublisherIcon,
};
