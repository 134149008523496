import * as React from 'react';
import {
    List,
    Count,
    DatagridConfigurable,
    TextField,
    useListContext,
    Loading,
    TopToolbar,
    FilterButton,
    useTranslate, CreateButton, ReferenceInput, AutocompleteArrayInput,
} from 'react-admin';

import {useMediaQuery, Divider, Tabs, Tab, Theme} from '@mui/material';

import {Fragment, useCallback, useEffect, useState} from 'react';
import EntityMobileList from "./MobileListItem";
import {ListProps} from "../fields/RowStyle";
import WebsiteReferenceField from "../websites/WebsiteReferenceField";
import {isEmptyArray} from "../fields/util";
import SearchWithButton from "../fields/SearchWithButton";


const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
        <FilterButton/>
    </TopToolbar>
);


const EntityList = () => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const translate = useTranslate();
    const [localState, setLocalState] = useState({websiteId_in: []});

    const websiteFilter = [<SearchWithButton source="name_contains" resettable alwaysOn/>,
        <ReferenceInput source="websiteId_in" reference="websites" alwaysOn={!isXSmall}
                        filter={isEmptyArray(localState?.websiteId_in) ? {} : {orIds: localState.websiteId_in}}>
            <AutocompleteArrayInput variant={"outlined"}
                                    optionText={(choice?: any) =>
                                        choice?.id // the empty choice is { id: '' }
                                            ? `${choice.domain}`
                                            : ''
                                    }
                                    filterToQuery={(q) => {
                                        return {domain_contains: q, orIds: localState.websiteId_in}
                                    }}
            />
        </ReferenceInput>,];

    return (

        <List
            filterDefaultValues={{status_equals: 'activate'}}
            sort={{field: 'id', order: 'DESC'}}
            perPage={25}
            filters={websiteFilter}
            actions={<ListActions/>}
            title={translate('resources.codes.name')}
        >
            <TabbedDataGrid isXSmall={isXSmall} setFiltered={setLocalState}/>
        </List>
    )
};

const tabs = [
    {id: 'activate', name: 'Activate'},
    {id: 'deactivate', name: 'Deactivate'},
];

const TabbedDataGrid = (props: ListProps) => {
        const {isXSmall, setFiltered} = props;
        const {data, isLoading, total, filterValues, setFilters, displayedFilters} = useListContext();
        useEffect(() => {
            setFiltered(filterValues)
        }, [filterValues]);


        const handleChange = useCallback(
            (event: React.ChangeEvent<{}>, value: any) => {
                setFilters &&
                setFilters(
                    {...filterValues, status_equals: value},
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
            },
            [displayedFilters, filterValues, setFilters]
        );

        if (isLoading) {
            return <Loading/>
        }

        return (
            <Fragment>
                <Tabs
                    variant="fullWidth"
                    centered
                    value={filterValues.status_equals}
                    indicatorColor="primary"
                    onChange={handleChange}
                >
                    {tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={
                                <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        'status_equals': choice.id,
                                    }}
                                    sx={{lineHeight: 'inherit'}}
                                />
                                )
                            </span>
                            }
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider/>
                {isXSmall ? (
                    <EntityMobileList context={{data, isLoading, total}}/>
                ) : (
                    <>
                        {['activate', 'deactivate'].includes(filterValues.status_equals) && (
                            <DatagridConfigurable data={data} rowClick="edit" bulkActionButtons={false}>
                                <TextField source="name"/>
                                <TextField source="html"/>
                                <WebsiteReferenceField link={false}/>
                            </DatagridConfigurable>
                        )}
                    </>
                )}
            </Fragment>
        );
    }
;

export default EntityList;
