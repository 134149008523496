import * as React from 'react';
import {
    Edit,
    Form,
    SelectInput,
    TextInput,
    ReferenceInput,
    NumberInput,
    AutocompleteInput,
    useTranslate, Toolbar, useRecordContext, EditProps, useGetIdentity,
} from 'react-admin';

import {Box, Card, CardContent, CardHeader, Grid, IconButton, Stack, Typography} from "@mui/material";
import CreateOnlyTextInput from "../fields/CreateOnlyTextInput";
import {Spacer} from "../fields/Space";
import DatePickerFormatable from "../fields/DatePickerFormatable";
import {RichTextInput} from 'ra-input-rich-text';
import {useCallback, useMemo} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import {isEmptyObject, StatusChoices} from "../fields/util";
import {EditToolbar} from "../fields/EditWithToolbar";


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.domain) {
        errors.domain = 'ra.validation.required';
    }
    if (!values.status) {
        errors.status = 'ra.validation.required';
    }
    if (!values.rateStartDate) {
        errors.rateStartDate = 'ra.validation.required';
    }
    if (!values.rateEndDate) {
        errors.rateEndDate = 'ra.validation.required';
    }
    return errors;
};

export const transformWebsiteData = (data: any) => {
    return {
        ...data,
        note: data?.note === '<br/><br/><br/><br/>' ? null : data?.note,
        user: isEmptyObject(data?.user?.id) ? null : {id: data.user.id}
    };
}

interface Props extends EditProps<any> {
}

export const EntityTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();
    return (<span>
        {isEmptyObject(record?.id) ? translate('resources.websites.page.create') : `: ${record.domain}`}
    </span>)
}

const EntityEdit = (props: Props) => {
    const theme = useTheme();

    return (<Edit redirect={"show"} title={<EntityTitle/>} transform={transformWebsiteData} {...props} component="div"
                  sx={{background: theme.palette.action.selected}}>
        <FormWebsite/>
    </Edit>)
}

// {
//     "id" : 35629551,
//     "note" : "",
//     "rate" : 0.8,
//     "rateStartDate" : "2023-03-13",
//     "rateEndDate" : "2026-03-13",
//     "lockDate" : null,
//     "rates" : null
// }

const getDefaultNote = (record: any) => {
    return record?.note ? record.note : '<br/><br/><br/><br/>';
}

export const FormWebsite = (props: Props) => {
    const record = useRecordContext();
    const {identity, isLoading: identityLoading} = useGetIdentity();
    const isAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA');

    const recordFix = useMemo(() => {
        return {...record, note: getDefaultNote(record)};
    }, [record])
    const translate = useTranslate();

    const navigate = useNavigate();
    const handleClose = useCallback(() => {
        navigate('/websites');
    }, [navigate]);


    return (<Form validate={validateForm} record={recordFix}>
        <Box maxWidth="60em" sx={{xs: 6, sm: 1}}>
            <Card>
                <CardContent>
                    <Stack direction="row" sx={{width: "100%"}} mb={2} pt={4}>
                        <Typography variant="h6"
                                    flex={1}>
                        </Typography>
                        <IconButton onClick={handleClose} size="small">
                            <CloseIcon/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.websites.fieldGroups.info'
                                )}
                            </Typography>

                            <Grid item xs={12} sm={12} md={10}>
                                <CreateOnlyTextInput source="domain" isRequired
                                                     isEdit={(formData: any) => !(formData.id > 0)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                <TextInput source="rootDomain" isRequired/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                <SelectInput
                                    isRequired
                                    fullWidth
                                    source="status"
                                    choices={StatusChoices}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                <ReferenceInput source="user.id" reference="admin/users">
                                    <AutocompleteInput fullWidth
                                                       optionText={(choice?: any) =>
                                                           choice?.id // the empty choice is { id: '' }
                                                               ? `${choice.login}`
                                                               : ''
                                                       }
                                                       filterToQuery={(s) => {
                                                           return {email_contains: s};
                                                       }}
                                    />
                                </ReferenceInput>
                            </Grid>
                        </Grid>

                        {isAdmin && <Grid item xs={12} sm={12} md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.websites.fieldGroups.config'
                                )}
                            </Typography>
                            <Grid item xs={12} sm={12} md={12}>
                                <NumberInput source="rate" fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <DatePickerFormatable source="rateStartDate" resource={"websites"}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <DatePickerFormatable source="rateEndDate" resource={"websites"}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <DatePickerFormatable source="lockDate" resource={"websites"}/>
                            </Grid>
                        </Grid>}
                        <Spacer/>
                        <Grid item xs={12} sm={12} md={12}>
                            <RichTextInput source="note" fullWidth/>
                        </Grid>
                        <Spacer/>
                    </Grid>
                </CardContent>
                <EditToolbar field={"domain"}/>
            </Card>
        </Box>

    </Form>)
}


export default EntityEdit;
