import * as React from 'react';
import {
    Button,
    Grid,
    Typography,
} from '@mui/material';
import {
    useTranslate,
    TextField,
    BulkDeleteButton,
    Datagrid, ReferenceInput, useInput
} from 'react-admin';
import {isEmptyArray, isEmptyObject} from "../fields/util";
import DoSomethingToRecord from "../fields/DoSomethingToRecord";
import {useState} from "react";
import {AutocompleteInputWithContext} from "../fields/AutocompleteInputWithContext";
import DeleteIcon from '@mui/icons-material/Delete'

interface Props {
    title: string
}

const ManagedUser = (props: Props) => {
    const translate = useTranslate();
    const {field} = useInput({source: "users"});
    const [localState, setLocalState] = useState({users: [], fetch: 0, state: 0});
    if (isEmptyObject(field?.value)) {
        return null;
    }

    const change = (evt: any) => {
        const findUser = localState.users.find((u: any) => u.id === evt)
        if (isEmptyObject(findUser)) {
            return;
        }
        const newUser = findUser;
        if (isEmptyObject(field.value) || isEmptyArray(field.value)) {
            field.onChange([newUser])
            return;
        }
        if (!isEmptyObject(field.value.find((u: any) => u.id === evt))) {
            return;
        }
        field.onChange([newUser, ...field.value])
    }

    const deleteUser = (userId: any) => {
        field.onChange(field?.value?.filter((u: any) => u.id !== userId))
    }


    const PostBulkActionButtons = () => (
        <>
            <a>xxx</a>
            {/*<ResetViewsButton label="Reset Views" />*/}
            {/* default bulk delete action */}
            <BulkDeleteButton/>
        </>
    );
    // #/admin/users/33633001
    const sort = {field: 'id', order: 'desc'} as any;
    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate(
                    'resources.publishers.fieldGroups.manage'
                )}
            </Typography>

            <ReferenceInput source="Add new publisher to manage" reference="admin/users">
                <AutocompleteInputWithContext fullWidth
                                              optionText={(choice?: any) =>
                                                  choice?.id // the empty choice is { id: '' }
                                                      ? `${choice.login}`
                                                      : ''
                                              }
                                              filterToQuery={(s: any) => {
                                                  return {email_contains: s};
                                              }}
                                              setChoices={(choices: any) => {
                                                  if (choices.length > 0) {
                                                      setLocalState({...localState, users: choices})
                                                  }
                                              }}
                                              onChange={change}
                />
            </ReferenceInput>
            {field?.value.length > 0 && <Datagrid
                bulkActionButtons={<PostBulkActionButtons/>}
                data={field?.value}
                sort={sort}
            >
                <TextField source="login"/>
                <TextField source="email"/>
                <DoSomethingToRecord source={"id"} startIcon={<DeleteIcon/>}
                                     onClick={deleteUser}
                                     text={translate('resources.publishers.fieldGroups.delete')}/>
            </Datagrid>
            }
        </>
    );
}

export default ManagedUser;