import AdUnitIcon from '@mui/icons-material/AcUnit';

import AdUnitList from './List';
import AdUnitEdit from './Edit';
import AdUnitCreate from './Create';

export default {
    list: AdUnitList,
    edit: AdUnitEdit,
    create: AdUnitCreate,
    icon: AdUnitIcon,
};
