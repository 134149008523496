import {
    Box,
    Card,
    CardContent, Chip,
    Grid, Table, TableBody, TableCell, TableHead, TableRow,
    useTheme
} from "@mui/material";
import {
    useRecordContext,
    useGetManyReference,
    useTranslate, useGetOne,
    Confirm
} from "react-admin";
import * as React from "react";
import {useEffect, useState} from "react";
import {isEmptyArray, isEmptyObject} from "../fields/util";
import InfoIcon from '@mui/icons-material/Info';
import Advertiser from "../advertisers";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowLeft';
import {fetchJson} from "../App";


interface AsideProps {
    setState: (evt: any) => void,
    state: any;
}

const Aside = (props: AsideProps) => {
    const record = useRecordContext<any>();

    return (<Box maxWidth={1 / 2} display={{xs: 'none', lg: 'block',}}>
        {record && <AsideView {...props}/>}
    </Box>)
}

interface DFPDataProps {
    id: any
    name: any
    setDelete: any
}

const DFPData = (props: DFPDataProps) => {
    const [localState, setLocalState] = useState({open: false, loading: false, total: -1})

    useEffect(() => {
        fetchJson(`/api/report-ad-exchanges/count?zAdDFPId.equals=${props.id}`)
            .then(({
                       headers,
                       json
                   }) => {
                setLocalState({...localState, total: json})
            })
    }, [props.id])
    const handleConfirm = () => {
        setLocalState({...localState, loading: true})
        if (localState.total > 0) {
            fetchJson(`/api/report-ad-exchanges/deleteBy?zAdDFPId.equals=${props.id}`,
                {method: 'DELETE'})
                .then(({
                           headers,
                           json
                       }) => {
                    setLocalState({...localState, open: false, loading: false, total: json})
                })
        }

        if (localState.total === 0) {
            fetchJson(`/api/ad-dfps/${props.id}`,
                {method: 'DELETE'})
                .then(({
                           headers,
                           json
                       }) => {
                    props.setDelete(props.id);
                    setLocalState({...localState, open: false, loading: false, total: -1})
                })
        }
    }
    const handleDialogClose = () => {
        setLocalState({...localState, open: false})
    }

    return (<span>
        {localState.total > 0 &&
        <Chip label={`delete data ${localState.total} rows`} color={"secondary"} onClick={() => {
            setLocalState({...localState, open: true})
        }}/>}
        {localState.total == 0 && <Chip label={"delete unit"} color={"warning"} onClick={() => {
            setLocalState({...localState, open: true})
        }}/>}
        <Confirm
            isOpen={localState.open}
            loading={localState.loading}
            title={`Delete post ${props.name}`}
            content="Are you sure you want to delete data related to item?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </span>)
}

const TextLink = (data: { link: string, text: string, theme: string }) => {
    const colorStyle = data.theme === "light" ? {color: "white"} : {};
    return <a target={"_blank"} href={data.link} style={colorStyle}>{data.text}</a>
}

const AsideView = (props: AsideProps) => {
    const {setState, state: remoteState} = props;
    const theme = useTheme();
    const [localState, setLocalState] = useState({showInfo: false, deleted: [] as any, open: false, loading: false})
    const {data: dfpsFromWebsite} = useGetManyReference(
        'ad-dfps',
        {
            target: 'websiteId',
            id: remoteState?.website,
            pagination: {page: 1, perPage: remoteState?.website === 0 ? 0 : 10},
            sort: {field: 'id', order: 'DESC'},
            filter: {
                adUnitId_specified: false,
                isWebsiteForUserFilter: 1,
                noLimit: 1
            }
        }
    );
    const {data: dfpsFromAdvertisers} = useGetManyReference(
        'ad-dfps',
        {
            target: 'sourceId.in',
            id: remoteState?.sources?.map((source: any) => source.id).join(","),
            pagination: {page: 1, perPage: remoteState?.sources.length === 0 ? 0 : 10},
            sort: {field: 'id', order: 'DESC'},
            filter: {
                adUnitId_specified: false,
                isWebsiteForUserFilter: 1,
                noLimit: 1
            }
        }
    );

    const getDFP = () => {
        const d1 = typeof dfpsFromWebsite === "undefined" || isEmptyArray(dfpsFromWebsite) ? [] : dfpsFromWebsite;
        const d2 = typeof dfpsFromAdvertisers === "undefined" || isEmptyArray(dfpsFromAdvertisers) ? [] : dfpsFromAdvertisers;
        const d1Ids = d1?.map(d => d.id);
        // lấy danh sách dfp từ 2 nguồn, lọc trùng
        const dx = [...d1, ...d2?.filter(d => !d1Ids?.includes(d.id))]
            // Loại bỏ những dfp đã chuyển sang bên edit sử dụng
            .filter((dfp: any) => !remoteState.movedDfps.map((d: any) => d.id).includes(dfp.id))
            .filter((dfp: any) => !localState.deleted.includes(dfp.id));
        return [...dx, ...remoteState.dfps] // lấy lại những dfp từ bên edit trả về (có thể gồm cả những dfp đã đưa sang sử dụng
        // nên không loại bỏ từ bước trước
    }

    const {
        data: website,
        error: websiteLoadError
    } = useGetOne('websites', {id: remoteState?.website || 0});

    const {data: advertisersFromWebsite, error: advertiserLoadError} = useGetManyReference(
        'sources',
        {
            target: 'userId',
            id: website?.user?.id || 0,
            pagination: {page: 1, perPage: isEmptyObject(website?.user?.id) ? 0 : 10},
            sort: {field: 'id', order: 'DESC'}
        }
    );

    const getAdvertisers = () => {
        const d1 = typeof advertisersFromWebsite === "undefined" ? [] : advertisersFromWebsite;
        return [...d1, ...remoteState.sources]
    }

    const clickHandler = (a: any) => {
        const dfps = remoteState.dfps.find((dfp: any) => dfp.id === a.id) ?
            remoteState.dfps.filter((dfp: any) => dfp.id !== a.id) : remoteState.dfps;

        setState({
            ...remoteState,
            dfps,
            addNew: {...a, color: "primary", time: new Date().getTime()},
            movedDfps: [...remoteState.movedDfps, a]
        })
    }
    const translate = useTranslate();

    const toggleShowInfo = () => {
        setLocalState({...localState, showInfo: !localState.showInfo})
    }

    const getColor = (c: any): any => {
        return c === "warning" ? theme.palette.warning.light : theme.palette.background
    }

    const convertName = (name: string) => {
        if (isEmptyObject(name)) {
            return "";
        }
        const splited = name.split('»');
        return splited.length === 3 ? `${splited[1]} ${splited[2]}` : name;
    }

    const handleConfirm = () => {
        getDFP().forEach(d => {
            fetchJson(`/api/ad-dfps/${d.id}`,
                {method: 'DELETE'})
                .then(({
                           headers,
                           json
                       }) => {
                    setLocalState({...localState, open: false, deleted: [...localState.deleted, d.id]})
                })
        })
        setLocalState({...localState, open: false, loading: false})
    }

    const handleDialogClose = () => {
        setLocalState({...localState, open: false, loading: false})
    }

    return (<>
        <Card
            sx={{
                background: theme =>
                    theme.palette.mode === 'dark'
                        ? '#535353'
                        : `linear-gradient(293deg, #3ac5f3 0%, #6ed8fb 100%)`,
                color: '#fff',
                padding: '20px',
                marginBottom: '1em',
            }}
        >
            <Confirm
                isOpen={localState.open}
                loading={localState.loading}
                title={`Delete all unused unit?`}
                content="Are you sure you want to delete all to item?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
            <Box display="flex">
                <Box flex="1">
                    <Box>
                        <h4> {translate('resources.adunits.help')}
                            <a onClick={toggleShowInfo}>&nbsp;
                                <InfoIcon/> </a>
                        </h4>
                    </Box>
                    {localState.showInfo && <Box>
                        <p>Website: <TextLink link={`/#/websites/${website?.id}`}
                                              text={website?.domain}
                                              theme={theme.palette.mode}/></p>
                        <p>Publisher: <TextLink link={`/#/admin/users/${website?.user?.id}`}
                                                text={website?.user?.login}
                                                theme={theme.palette.mode}/></p>
                        <div>Advertisers: {getAdvertisers()?.map(advertiser => {
                            return <a target={"_blank"} key={advertiser.id} href={`/#/sources/${advertiser.id}`}>
                                <Chip icon={<Advertiser.icon color="secondary"/>} label={advertiser.name}
                                      variant="outlined"
                                      sx={{margin: 0.2}}/>&nbsp;
                            </a>
                        })}</div>
                    </Box>}
                </Box>
            </Box>
        </Card>
        {getDFP().length > 0 && <Box>
            <Card>
                <CardContent>
                    <Table aria-label="left table" className={"left-table"}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>Name</TableCell>
                                <TableCell><Chip label={`Delete all units`} color={"warning"} onClick={() => {
                                    setLocalState({...localState, open: true})
                                }}/></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getDFP().map((dfp: any, idx: number) => {
                                const key = `map-key-${idx}`;
                                return (
                                    <TableRow
                                        className={"row"}
                                        key={key}
                                        sx={{
                                            backgroundColor: getColor(dfp?.color),
                                        }}
                                    >
                                        <TableCell onClick={() => clickHandler(dfp)}><ArrowBackIosNewIcon/></TableCell>
                                        <TableCell
                                            onClick={() => clickHandler(dfp)}>{dfp.source?.name} {convertName(dfp.name)}</TableCell>
                                        <TableCell>{dfp.website?.domain} <br/>
                                            <DFPData id={dfp.id} name={convertName(dfp.name)}
                                                     setDelete={(id:any) => setLocalState({
                                                         ...localState,
                                                         deleted: [...localState.deleted, id]
                                                     })}/></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

        </Box>}
    </>);
}

export default Aside;