import * as React from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    useTranslate,
    Form, useRecordContext, SelectInput, ReferenceInput, AutocompleteInput, SaveButton,
} from 'react-admin';
import {Card, CardContent, Box, Grid, Typography} from '@mui/material';


import {Spacer} from "../fields/Space";
import CreateOnlyTextInput from "../fields/CreateOnlyTextInput";
import {FieldMinWidth60, FieldMinWidth90, isEmptyObject, StatusChoices} from "../fields/util";
import {AdvertiserTypeChoices, AdvertiserTimeZones} from "./List";
import DatePickerFormatable from "../fields/DatePickerFormatable";
import PathField from "./PathField";
import {useMemo} from "react";
import {SpanSpace} from "../fields/SpanSpace";
import {EditToolbar} from "../fields/EditWithToolbar"

export const EntityTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();
    return (<span>
        {isEmptyObject(record?.id) ? translate('resources.advertisers.page.create') : `${translate('resources.advertisers.name')}: ${record.name}`}
    </span>)
}

const transformAdvertiserData = (data: any) => {
    return {
        ...data,
        path: (isEmptyObject(data.extra) || typeof data.extra === "string" || typeof data.extra === "number") ? data.path : JSON.stringify(data.extra),
    };
}


const EntityEdit = () => (
    <Edit title={<EntityTitle/>}
          redirect={"show"}
          component="div" transform={transformAdvertiserData}>
        <AdvertiserForm/>
    </Edit>
);

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.idx) {
        errors.idx = 'ra.validation.required';
    }
    return errors;
};


const parseExtra = (record: any) => {
    return record.type !== "Google Sheet" ? record.path : JSON.parse(record.path);
}

export const AdvertiserForm = () => {
    const record = useRecordContext();
    const recordFix = useMemo(() => {
        return {...record, extra: parseExtra(record)};
    }, [record])
    const translate = useTranslate();

    return (
        <Form record={recordFix} validate={validateForm}>
            <Box maxWidth="70em">
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <CreateOnlyTextInput source="idx" isRequired sx={{minWidth: "100%"}}
                                                     isEdit={(formData: any) => !(formData.id > 0)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>

                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.advertisers.fieldGroups.info'
                                    )}
                                </Typography>

                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput fullWidth source="name" sx={FieldMinWidth90} name={'name'}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ReferenceInput source="user.id" reference="admin/users">
                                        <AutocompleteInput fullWidth sx={FieldMinWidth90}
                                                           optionText={(choice?: any) =>
                                                               choice?.id // the empty choice is { id: '' }
                                                                   ? `${choice.login}`
                                                                   : ''
                                                           }
                                                           filterToQuery={(s) => {
                                                               return {email_contains: s};
                                                           }}
                                        />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <DatePickerFormatable source="startDate" resource={"advertisers"}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>

                                <Typography variant="h6" gutterBottom>
                                    <SpanSpace/>
                                </Typography>
                                <Grid item xs={12} sm={12} md={12}>
                                    <SelectInput
                                        fullWidth
                                        isRequired
                                        source="status"
                                        choices={StatusChoices}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <SelectInput source="type" fullWidth
                                                 choices={AdvertiserTypeChoices}
                                    />
                                    <SelectInput source="timezone" fullWidth
                                                 choices={AdvertiserTimeZones}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Spacer/>

                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.advertisers.fieldGroups.config'
                            )}
                        </Typography>
                        <PathField sx={{minWidth: '100%'}}/>
                        <Spacer/>
                    </CardContent>
                    <EditToolbar />
                </Card>
            </Box>
        </Form>
    );
};


export default EntityEdit;
