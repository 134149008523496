

import "./style.css"
import {CommonInputProps} from "react-admin";
interface Props extends CommonInputProps {
}

const EmptyField = (prop:Props) => {
        return <div className={"break"}/>
}

export  default EmptyField;

