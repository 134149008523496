import * as React from 'react';
import {
    AppBar,
    TitlePortal,
    LocalesMenuButton, useLocales,
    LoadingIndicator, UserMenu, Logout, useTranslate, LogoutClasses, useTheme
} from 'react-admin';
import {Box, useMediaQuery, Theme, ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import Logo from './Logo';
import './style.css';
import Brightness7Icon from "@mui/icons-material/Brightness7";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";
import {styled} from "@mui/material/styles";
import PersonPinIcon from '@mui/icons-material/PersonPin';

const CustomToolbar = () => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    const locales = useLocales();
    return (
        <>
            {isLargeEnough && locales && locales.length > 1 ? <LocalesMenuButton/> : null}
            <LoadingIndicator/>
        </>
    );
}

const StyledMenuItem = styled(MenuItem, {
    name: "mz",
    overridesResolver: (props, styles) => styles.root,
})({
    [`& .${LogoutClasses.icon}`]: {},
});

interface CustomMenuProps {
    handleClick: any
    icon: any
    text: any
}

const CustomMenu = (props: CustomMenuProps) => {
    const {handleClick, icon, text, ...rest} = props;
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const translate = useTranslate();
    return (<StyledMenuItem
        onClick={handleClick}
        // @ts-ignore
        component={isXSmall ? 'span' : 'li'}
        {...rest}
    >
        <ListItemIcon className={LogoutClasses.icon}>
            {icon ? icon : <ExitIcon fontSize="small"/>}
        </ListItemIcon>
        <ListItemText>
            {translate(text)}
        </ListItemText>
    </StyledMenuItem>)
}

const CustomAppBar = () => {
    const locales = useLocales();
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    const [theme, setTheme] = useTheme('light');
    const handleTogglePaletteType = (): void => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };
    return (
        <AppBar color="secondary" elevation={1}
                toolbar={<CustomToolbar/>}
                userMenu={
                    <UserMenu icon={<PersonPinIcon/>}>
                        {!isLargeEnough && locales && locales.length > 1 ? <LocalesMenuButton/> : null}
                        <CustomMenu icon={<Brightness7Icon/>} handleClick={handleTogglePaletteType}
                                    text={"pos.theme.name"}/>
                        <Logout/>
                    </UserMenu>
                }
        >
            {<Logo/>}
            {isLargeEnough && <TitlePortal className={"zm-title"}/>}
            {isLargeEnough && <Box component="span" sx={{flex: 1}}/>}
        </AppBar>
    );
};

export default CustomAppBar;
