import {CopyToClipboard} from 'react-copy-to-clipboard';
import {CommonInputProps, useRecordContext, TextField} from "react-admin";
import {isEmptyObject} from "./util";

interface Props extends CommonInputProps {
    max: number,
    name?: string
}

import "./style.css"

const ReadOnlyCopyableField = (props: Props) => {
    const {source, max} = props
    const record = useRecordContext<any>();

    if (!record) {
        return null;
    }
    const data = record[source];

    if (isEmptyObject(data)) {
        return null;
    }
    return (<div className={"container-top-right fake-field"}>
        <TextField source={source}/>
        <CopyToClipboard text={data}>
            <button type={'button'} className={'btn btn-sm copy-button btn-secondary button-top-right'}>
                Copy
            </button>
        </CopyToClipboard>
    </div>)
}

ReadOnlyCopyableField.defaultProps = {
    max: 50
}

export default ReadOnlyCopyableField