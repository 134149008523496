import * as React from 'react';
import {Admin, fetchUtils, Resource, HttpError, CustomRoutes} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from './authProvider';
import {Login, Layout} from './layout';
import {Dashboard} from './dashboard';
import englishMessages from './i18n/en';
import {lightTheme, darkTheme} from './layout/themes';

import websites from './websites';
import publishers from './publishers';
import advertisers from './advertisers';
import adunits from './adunits';
import code from './code';
import report from './reports';
import analytic from './analytic';
import dataProviderFactory from './dataProvider';
import {isEmptyObject} from "./fields/util";
import {Route} from "react-router";
import FixDateReport from "./config/FixDateReport";
import AdsenseAccount from "./config/AdsenseAccount";
import AdvertiserFactor from "./config/AdvertiserFactor";
import AdvertiserFactorEdit from "./config/AdvertiserFactorEdit";
import AdvertiserFactorCreate from "./config/AdvertiserFactorCreate";

const i18nProvider = polyglotI18nProvider(
    locale => {
        if (locale === 'vi') {
            return import('./i18n/vi').then(messages => messages.default);
        }

        // Always fallback on english
        return englishMessages;
    },
    'en',
    [
        {locale: 'en', name: 'English'},
        {locale: 'vi', name: 'Tiếng Việt'},
    ]
);

const getFieldErrorsMessage = (body: any) => {
    return `${JSON.stringify(body.fieldErrors)}`
}

const orZero = (data: any) => {
    if (isEmptyObject(data)) {
        return 0;
    }
    return data;
}

export const fetchJson = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    // add your own headers here
    
    if (localStorage.getItem('auth') != null ) {
        options.headers.set('Authorization', `Bearer ${localStorage.getItem('auth')}`);
    }
    return fetchUtils.fetchJson(url, options).catch(err => {

        const validateMessage = (err.body?.message === "error.validation") ? `${getFieldErrorsMessage(err.body)}` : `Call to ${url} error`;
        if (err.status === 401) {
            localStorage.setItem('auth_count', orZero(localStorage.getItem('auth_count')) + 1);
            localStorage.removeItem('auth');
        }
        return Promise.reject(new HttpError(validateMessage, err.status, err.body))
    });
}

import { QueryClient } from 'react-query';


const App = () => {

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 3,
                structuralSharing: false,
            },
            mutations: {
                retryDelay: 10000,
            },
        },
    });

    return (
        <Admin
            title="Z"
            queryClient={queryClient}
            dataProvider={dataProviderFactory('/api', fetchJson, 'X-Total-Count')}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
            darkTheme={darkTheme}
            defaultTheme="light"
        >
            <Resource name="report-ad-exchanges/report" {...report} />
            <Resource name="report-ad-exchanges/analytic" {...analytic} />
            <Resource name="websites" {...websites} />
            <Resource name="admin/users" {...publishers} />
            <Resource name="sources" {...advertisers} />
            <Resource name="ad-units" {...adunits} />
            <Resource name="codes" {...code} />
            <CustomRoutes>
                <Route path="/settings-adsense" element={<AdsenseAccount/>}/>
                <Route path="/settings-date/:id" element={<FixDateReport/>}/>
                <Route path="/settings-advertiser" element={<AdvertiserFactor/>}/>
                <Route path="/settings-advertiser/:id" element={<AdvertiserFactorEdit/>}/>
                <Route path="/settings-advertiser/create" element={<AdvertiserFactorCreate/>}/>
            </CustomRoutes>
        </Admin>
    )
}

export default App;
