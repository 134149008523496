import * as React from "react";
import {
    SimpleFormIterator,
    TextInput,
    ArrayInput,
    SelectInput, useInput
} from "react-admin";


import {isEmptyArray, isEmptyObject} from "../fields/util";
import {useEffect, useState} from "react";
import CodeTargetField from "../fields/CodeTargetField";
import {TableFormIterator} from "../fields/TableFormIterator";

interface Props {
    source: string,
    name: string,
    resource?: string,
    isRequired?: boolean,
    sx?: any,
}

export const TARGET_TYPE = [
    {id: 'IP', name: 'IP'},
    {id: 'OS', name: 'Operating system'},
    {id: 'Location', name: 'Location'},
    {id: 'Device', name: 'Device'},
    {id: 'URI', name: 'URI'},
    {id: 'View', name: 'Page view each Browser'},
    {id: 'ViewDay', name: 'Page view each Browser / Day'},
];


const NEW_LAYER = {id: 1, idx: 1, rate: 1, type: 'extract', media: {type: "code"}, fallbackMedia: {type: "code"}}

const CodeLayerView = (props: Props) => {
    const {source} = props;
    const {field, fieldState} = useInput({source} as any);
    const [localState, setLocalState] = useState({layer: 0, layers: [] as any[]});

    useEffect(() => {
        const layers = isEmptyArray(field.value) ? [] : field?.value as any[];
        setLocalState({...localState, layers})
    }, [field.value])

    return <>
        <ArrayInput source={`layers`} name={`layers`}>
            <TableFormIterator
                onRemove={(fieldRemove: any) => {
                    const afterDeleted = field?.value?.map((fv: any) => {
                        if (fv.idx !== fieldRemove.idx) {
                            return fv;
                        }
                        return {...fv, deleted: true}
                    })
                    field.onChange(afterDeleted)
                }}
                onAdd={(fieldAdd: any) => {
                    field.onChange([...field.value, fieldAdd])
                }}
                inline
                disableReordering
                disableClear
                view={"rate"}
                defaultValue={NEW_LAYER}>
                <TextInput source={`rate`} helperText={false}/>
                <ArrayInput source={`targets`}>
                    <SimpleFormIterator inline>
                        <SelectInput isRequired
                                     source="target"
                                     choices={TARGET_TYPE}
                        />
                        <CodeTargetField source={"values"}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <span>
                    <TextInput
                        multiline
                        fullWidth
                        minRows={10}
                        source={`media.code`} helperText={false}/>
                </span>
            </TableFormIterator>
        </ArrayInput>

    </>
}

const CodeLayer = (props: Props) => {
        const {sx, source, name} = props;
        return (
            <CodeLayerView name={name} sx={sx} source={source}/>
        );
    }
;

export default CodeLayer