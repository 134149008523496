import dayjs from "dayjs";

export const defaultColors = [
    '#4472c4',
    '#5b9bd5',
    '#3cb44b',
    '#4363d8',
    '#911eb4',
    '#46f0f0',
    '#e6194b',
    '#ed7d31',
    '#ffe119',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#f58231',
    '#800000',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
    '#db4437',
    '#4285f4',
    '#0f9d58',
];

const defaultToolbar = {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
        download: true,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
    },
    export: {
        csv: {
            filename: 'zmedia',
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp: any) {
                return dayjs(new Date(timestamp)).format('YYYY-MM-DD');
            },
        },
        svg: {
            filename: 'data',
        },
        png: {
            filename: 'data',
        },
    },
    autoSelected: 'zoom',
};


export const apexDefaultPieOptions = {
    chart: {
        toolbar: defaultToolbar,
        type: 'pie',
    },
    labels: [],
    colors: defaultColors,
    legend: {
        position: 'bottom',
        horizontalAlign: 'center',
    },
    tooltip: {
        enabled: true,
        followCursor: true,
        shared: true,
        x: {
            show: true,
            format: 'YYYY-MM-DD',
            formatter(val: any) {
                return dayjs(new Date(val)).format('YYYY-MM-DD');
            },
        },
        y: {
            formatter(val: any) {
                return dayjs(new Date(val)).format('YYYY-MM-DD');
            },
            title: {
                formatter: (seriesName: any) => seriesName,
            },
        },
    },
    responsive: [
        {
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: 'bottom',
                },
            },
        },
    ],
};

export const apexDefaultOptions = {
    chart: {
        toolbar: defaultToolbar,
        type: 'area',
        stacked: false,
    },
    colors: ['#a5a5a5', '#ed7d31', '#5b9bd5', '#4472c4'],
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'smooth',
        width: 1,
    },
    tooltip: {
        enabled: true,
        followCursor: true,
        shared: true,
        x: {
            show: true,
            format: 'dd-MM-yyyy',
            formatter(val: any) {
                return dayjs(new Date(val)).format('YYYY-MM-DD');
            },
        },
        y: {
            formatter(val: any) {
                return dayjs(new Date(val)).format('YYYY-MM-DD');
            },
            title: {
                formatter: (seriesName: any) => seriesName,
            },
        },
    },
    fill: {
        type: 'gradient',
        gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
        },
    },
    legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 10,
        itemMargin: {
            horizontal: 10,
            vertical: 6,
        },
    },
    yaxis: [],
    xaxis: {
        type: 'datetime',
        labels: {
            rotate: -60,
            format: 'dd-MM-yyyy',
        },
        crosshairs: {
            width: 40,
        },
    },
};
