import * as React from 'react';
import {
    List,
    useListContext,
    SelectInput,
    TopToolbar,
    FilterButton,
    SearchInput,
    SelectArrayInput,
    SelectColumnsButton,
    TextField,
    DatagridConfigurable,
    Loading,
    useGetIdentity, downloadCSV,
    ExportButton, AutocompleteArrayInput,
    ReferenceInput, useLocaleState, useStore, Pagination, useTranslate
} from 'react-admin';

import {
    useMediaQuery,
    Theme,
    Card, CardHeader, CardContent
} from '@mui/material';

import '../reports/style.css';

import {ListProps} from "../fields/RowStyle";
import DatePickerFormatable from "../fields/DatePickerFormatable";
import {useEffect, useMemo, useState} from "react";
import {isEmptyArray, isEmptyObject} from "../fields/util";
import {useTheme} from "@mui/material/styles";
import SearchButton from "../reports/searchButton";
import dayjs, {Dayjs} from "dayjs";
import QuickDateFilter from "../fields/QuickDateFilter"
import EmptyField from "../fields/EmtyField";
import jsonExport from 'jsonexport/dist';
import {useListPaginationContext} from "ra-core";
import MoneyField from "../fields/MonyField";
import LongField from "../fields/LongField";
import {ResponsiveContainer} from "recharts";
import ReactApexChart from "react-apexcharts";
import {apexDefaultOptions, apexDefaultPieOptions} from "../dashboard/apex";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    flexRow: {display: 'flex', flexDirection: 'row'},
    leftCol: {flex: 2, marginRight: '0.5em'},
    rightCol: {flex: 1, marginLeft: '0.5em'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <SelectColumnsButton/>
        <ExportButton/>

    </TopToolbar>
);

const groupByChoices = [
    {
        id: 'website',
        name: 'Website',
    },
    {
        id: 'param',
        name: 'Param',
    },
    {
        id: 'date',
        name: 'Date',
    }
];

const typeChoices = [
    {
        id: 'geo',
        name: "Geometry"
    },
    {
        id: 'page',
        name: "Page"
    }
]


const EntityList = () => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    // useEffect(() => {
    //     const defaultColumns = localStorage.getItem("RaStore.preferences.report-ad-exchanges/report.datagrid.columns");
    //     if (isEmptyObject(defaultColumns)) {
    //         localStorage.setItem("RaStore.preferences.report-ad-exchanges/report.datagrid.columns", "[\"0\",\"1\",\"2\",\"3\",\"4\",\"5\",\"6\",\"7\",\"8\",\"9\",\"10\",\"11\"]")
    //     }
    // }, [])

    const [localState, setLocalState] = useState({
        tmp_userId_in: [],
        tmp_websiteId_in: [],
    });

    const filterAll = Object.assign({},
        isEmptyArray(localState.tmp_websiteId_in) ? null : {websiteId_in: localState.tmp_websiteId_in},
        isEmptyArray(localState.tmp_userId_in) ? null : {userId_in: localState.tmp_userId_in}
    );

    const entityFilters = [

        <SelectInput
            alwaysOn
            isRequired
            source="tmp_type_equal"
            choices={typeChoices}
            variant="outlined"
        />,
        <SelectArrayInput
            alwaysOn
            isRequired
            source="tmp_groupBy"
            choices={groupByChoices}
            variant="outlined"
        />,
        <ReferenceInput
            source="tmp_userId_in"
            reference="users"
            alwaysOn
            filter={isEmptyArray(localState?.tmp_userId_in) ? filterAll : {orIds: localState.tmp_userId_in, ...filterAll}}>
            <AutocompleteArrayInput
                variant={"outlined"}
                sx={{minWidth: "200px"}}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.login}`
                        : ''
                }
                filterToQuery={(q) => {
                    return {
                        login_contains: q, orIds: localState.tmp_userId_in,
                        ...filterAll
                    }
                }}
            />
        </ReferenceInput>,
        <ReferenceInput
            alwaysOn={!isXSmall}
            source="tmp_websiteId_in"
            reference="websites"
            filter={isEmptyArray(localState?.tmp_websiteId_in) ? filterAll : {orIds: localState.tmp_websiteId_in, ...filterAll}}>
            <AutocompleteArrayInput
                alwaysOn
                variant={"outlined"}
                sx={{minWidth: "200px"}}
                optionText={(choice?: any) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.domain}`
                        : ''
                }
                filterToQuery={(q) => {
                    return {
                        domain_contains: q, orIds: localState.tmp_websiteId_in,
                        status_equals: 'activate',
                        ...filterAll
                    }
                }}
            />
        </ReferenceInput>,
        <EmptyField alwaysOn source={"tmp_empty"} cellClassName={"break"}/>,
        <QuickDateFilter alwaysOn source={"tmp_tmp_1"} dateField={"tmp_date"}
                         label={"resources.report.filters.this_month"}/>,
        <DatePickerFormatable alwaysOn source="tmp_date_greaterThanOrEqual"/>,
        <DatePickerFormatable alwaysOn source="tmp_date_lessThanOrEqual"/>,
        <SearchButton source={"tmp_tmp_2"} alwaysOn/>
    ];


    const [availableColumns] = useStore<any[]>(`preferences.report-ad-exchanges/report.datagrid.availableColumns`, []);
    const [columns] = useStore<any[]>(`preferences.report-ad-exchanges/report.datagrid.columns`, availableColumns.map(c => c.index));
    const exporter = (posts: any) => {
        const headers = availableColumns.filter(f => columns.includes(f.index)).map(f => f.source);

        const postsForExport = posts.map((post: any) => {
            const output = {} as any
            headers.forEach((h: any) => {
                output[h] = post[h]
            })
            return output;
        });
        jsonExport(postsForExport, {
            headers: headers // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'Zholding'); // download as 'posts.csv` file
        });
    };
    const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100, 200]}/>;

    return (

        <List
            pagination={<PostPagination/>}
            exporter={exporter}
            filterDefaultValues={{groupBy: 'param', type: 'geo', tmp_type_equal:'geo', tmp_groupBy:'param'}}
            filters={entityFilters}
            actions={<ListActions/>}
            title="Analytics"

            className={"report-list"}
        >
            <TabbedDataGrid isXSmall={isXSmall} setFiltered={setLocalState}/>
        </List>
    )
};

const getRowStyle = (total: number, theme: any) => (record: any) => {
    if (record.id === total) {
        return {
            backgroundColor: theme.palette.info.main
        }

    }
    return {}
}

export const getRate = (currency: any, thisDate: number = dayjs().unix()) => {
    if (isEmptyObject(currency.rate)) {
        return currency?.rateDefault;
    }
    if (isEmptyObject(currency.rate.find)) {
        return 1;
    }
    const rate = currency.rate.find((r: any) => {
        return dayjs(r?.fromDate, "YYYY-MM-DD").unix() <= thisDate && dayjs(r?.toDate, "YYYY-MM-DD").unix() > thisDate;
    });

    if (!isEmptyObject(rate) && !isEmptyObject(rate.rate)) {
        return rate.rate;
    }

    return currency?.rateDefault;
}


const processPie = (data: any[], options: any, dim: string[], field: string) => {
    const optionsPie: any = {...apexDefaultPieOptions};
    if (typeof data === "undefined" || data.length <= 1) {
        return {options: optionsPie, series: [], labels: [], total: 0}
    }
    const pointData = data.map(f => f[field]);
    const length = Math.min(data.length, 5);
    const series = [...pointData.slice(0, length), pointData.slice(length).reduce((ac: any, a: any) => ac + a, 0)];
    optionsPie.labels = [...data.slice(0, length).map(f => {
        return dim.map(d => f[d]).join("-");
    }), "Others"];

    // optionsPie.tooltip.y.formatter = (val: any) => formatter.format(val * moneyRate);

    return {
        options: optionsPie,
        series: series,
        labels: optionsPie.labels,
        total: series.reduce((ac, a) => ac + a, 0)
    }
}

const TabbedDataGrid = (props: ListProps) => {
    const {isXSmall, setFiltered} = props;
    const {filterValues, data, total, isLoading} = useListContext();
    const theme = useTheme();
    const {identity, isLoading: identityLoading} = useGetIdentity();
    const isAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA') || identity?.authorities.includes('ROLE_ACCOUNTING');
    const [locale, _] = useLocaleState();
    const langKey = isAdmin ? locale : identity?.langKey;
    const optionsString = JSON.stringify(apexDefaultOptions);
    const options = JSON.parse(optionsString);
    const translate = useTranslate();
    const {page, perPage, setPerPage} = useListPaginationContext();
    const [totalInitObject, setTotalInitObject] = useState({} as any)
    useEffect(() => {
        if (window.localStorage.length > 200) {
            window.localStorage.clear();
        }
        setPerPage(20)
    }, [])

    useEffect(() => {
        localStorage.setItem("langKey", langKey);
        setFiltered(filterValues)
    }, [filterValues]);

    const groupByIg = isEmptyObject(filterValues.groupBy)  ? ["param"] : filterValues.groupBy;
    const groupBy = typeof groupByIg === "string" ? [groupByIg] : groupByIg;

    useEffect(() => {
        const newTotalInitObject = {} as any
        groupBy.forEach( (g: any) => {
            newTotalInitObject[g] = "All";
        })
        setTotalInitObject(newTotalInitObject);

    }, [filterValues.groupBy])

    if (isLoading || identityLoading) {
        return <Loading/>
    }

    if (isEmptyArray(data)) {
        return <p>No data</p>
    }

    const pieRevenue = processPie(data, options, groupBy, langKey === "en" ? "estimatedRevenue" : "estimatedRevenueVnd");
    const pieImpression = processPie(data, options, groupBy, "impression");
    const totalData = data?.reduce((ac, value) => {
        Object.keys(value).forEach(key => {
            if (groupBy.includes(key)) {
                return;
            }
            if (ac[key] === '' || ac[key] === 'Infinity' || value[key] === 'Infinity') {
                return;
            }
            ac[key] = (isEmptyObject(ac[key]) ? 0 : ac[key]) + (isEmptyObject(value[key]) ? 0 : value[key])
        })
        return ac
    }, {...totalInitObject});

    const totalDataPoint = {
        ...totalData,
    }
    const dataToShow = data.slice(perPage * (page - 1), page * perPage);
    return (
        <div style={{flex: 1}}>
            {isXSmall ? (
                <DatagridConfigurable className={"report-table report-table-small"}
                                      data={[totalDataPoint, ...dataToShow].map((d: any) => {
                                          return {...d, id: d.id + 1}
                                      })}
                                      total={total + 1}
                                      rowStyle={getRowStyle(0, theme)}
                                      isLoading={isLoading}>
                    {!isEmptyObject(totalInitObject["website"]) && <TextField source="website"/>}
                    {!isEmptyObject(totalInitObject["date"]) && <TextField source="date"/>}
                    {!isEmptyObject(totalInitObject["param"]) &&
                    <TextField source="param" label={typeChoices.find((t:any) => t.id === filterValues.type)?.name}/>}
                    <LongField source="click" lang={langKey}/>
                    <LongField source="impression" lang={langKey}/>
                    {langKey === "en" && <MoneyField source="estimatedRevenue" rate={1} lang={langKey}/>}
                    {langKey !== "en" && <MoneyField source="estimatedRevenueVnd" rate={1} lang={langKey}/>}
                    <LongField source="request" lang={langKey}/>
                    <TextField />
                    <TextField />
                </DatagridConfigurable>
            ) : (
                <>
                    <div style={{display: "flex", flex: "2 1"}}>
                        <div style={{...styles.flex, minWidth: "70%", paddingRight: 4}}>
                            <Card sx={{width: "100%"}}>
                                <CardHeader titleTypographyProps={{variant: 'h6'}}
                                            title={translate('pos.analytic.area')}/>

                                <DatagridConfigurable className={"report-table report-table-lg"}
                                                      data={[totalDataPoint, ...dataToShow].map((d: any) => {
                                                          return {...d, id: d.id + 1}
                                                      })}
                                                      total={total + 1}
                                                      rowStyle={getRowStyle(0, theme)}
                                                      isLoading={isLoading}>
                                    {!isEmptyObject(totalInitObject["website"]) && <TextField source="website"/>}
                                    {!isEmptyObject(totalInitObject["date"]) && <TextField source="date"/>}
                                    {!isEmptyObject(totalInitObject["param"]) && <TextField source="param"
                                                                                            label={typeChoices.find((t:any) => t.id === filterValues.type)?.name}/>}
                                    <LongField source="click" lang={langKey}/>
                                    <LongField source="impression" lang={langKey}/>
                                    <LongField source="request" lang={langKey}/>
                                    {langKey === "en" &&
                                    <MoneyField source="estimatedRevenue" rate={1} lang={langKey}/>}
                                    {langKey !== "en" &&
                                    <MoneyField source="estimatedRevenueVnd" rate={1} lang={langKey}/>}
                                    <TextField />
                                    <TextField />
                                </DatagridConfigurable>
                            </Card>
                        </div>

                        <div style={{...styles.flexColumn, minWidth: "30%"} as any}>
                            <Card sx={{width: "100%", marginBottom: '4px'}}>
                                <CardHeader titleTypographyProps={{variant: 'h6'}}
                                            title={translate('pos.analytic.revenue')}/>
                                <CardContent>
                                    <div style={{width: '100%', height: 250}}>
                                        <ResponsiveContainer>
                                            <ReactApexChart options={pieRevenue.options} series={pieRevenue.series}
                                                            labels={pieRevenue.labels} type="pie"/>
                                        </ResponsiveContainer>
                                    </div>
                                </CardContent>
                            </Card>

                            <Card >
                                <CardHeader titleTypographyProps={{variant: 'h6'}}
                                            title={translate('pos.analytic.impression')}/>
                                <CardContent>
                                    <div style={{width: '100%', height: 250}}>
                                        <ResponsiveContainer>
                                            <ReactApexChart options={pieImpression.options} series={pieImpression.series}
                                                            labels={pieImpression.labels} type="pie"/>
                                        </ResponsiveContainer>
                                    </div>
                                </CardContent>
                            </Card>

                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default EntityList;
