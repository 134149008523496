import * as React from 'react';
import {Fragment} from 'react';
import {
    ListItem,
    ListItemText,
    Link as MuiLink, Divider,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {
    useCreatePath,
    TextField,
    useRecordContext, RecordContextProvider, useListContext,
} from 'react-admin';


const EntityListItem = () => {
    const record = useRecordContext<any>();
    const createPath = useCreatePath();
    if (!record) {
        return null;
    }
    return (
        <MuiLink
            to={createPath({
                resource: 'sources',
                type: 'edit',
                id: record.id,
            })}
            component={Link}
            underline="none"
            color="inherit"
        >
            <ListItem button>
                <ListItemText
                    primary={
                        <Fragment>
                            <TextField
                                source="name" variant={"subtitle1"}
                            />
                            <br/>
                            <TextField
                                source="idx" variant={"subtitle1"}
                            />
                            <br/>
                        </Fragment>
                    }
                    secondary={`${record.type}`}
                    secondaryTypographyProps={{noWrap: false}}
                />
            </ListItem>
            <Divider />
        </MuiLink>
    );
};

const EntityList = () => {
    const {data, isLoading, total} = useListContext<any>();
    if (isLoading || Number(total) === 0) {
        return null;
    }

    return (data.map(review => (
        <RecordContextProvider value={review} key={review.id}>
            <EntityListItem/>
        </RecordContextProvider>
    )))
}

export default EntityList;