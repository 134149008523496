import * as React from 'react';
import {Fragment} from 'react';
import {
    ListItem,
    ListItemText,
    Link as MuiLink, Divider,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {
    useCreatePath,
    TextField,
    useRecordContext, RecordContextProvider, useListContext, useGetIdentity,
} from 'react-admin';
import PublisherReferenceField from "../publishers/PublisherReferenceField";

interface Props {
    isAdmin?: boolean
}

const EntityListItem = (props: Props) => {
    const {isAdmin} = props;
    const record = useRecordContext<any>();
    const createPath = useCreatePath();
    if (!record) {
        return null;
    }
    return (
        <MuiLink
            to={createPath({
                resource: 'websites',
                type: 'edit',
                id: record.id,
            })}
            component={Link}
            underline="none"
            color="inherit"
        >
            <ListItem button>
                <ListItemText
                    primary={
                        <Fragment>
                            <TextField
                                source="domain" variant={"subtitle1"}
                            />
                            <br/>
                            {isAdmin && <TextField
                                source="rate" variant={"subtitle1"}
                            />}
                            <br/>
                            <PublisherReferenceField link={false}/>
                        </Fragment>
                    }
                    secondary={`${record.rateStartDate} - ${record.rateEndDate}`}
                    secondaryTypographyProps={{noWrap: false}}
                />
            </ListItem>
            <Divider/>

        </MuiLink>
    );
};

const EntityList = () => {
    const {data, isLoading, total} = useListContext<any>();
    if (isLoading || Number(total) === 0) {
        return null;
    }

    const {identity, isLoading: identityLoading} = useGetIdentity();
    const isAdmin = identity?.authorities.includes('ROLE_ADMIN') || identity?.authorities.includes('ROLE_ADMIN_DATA');

    return (data.map(review => (
        <RecordContextProvider value={review} key={review.id}>
            <EntityListItem isAdmin/>
        </RecordContextProvider>
    )))
}

export default EntityList;