import {fetchUtils, DataProvider} from 'ra-core';
import {Identifier, RaRecord} from "react-admin";

const setCache = (resource: string, url: string, data: any) => {
    try {
        localStorage.setItem(`RaStore.${resource}.${url}`, JSON.stringify(data))
    }
    catch(err) {
        console.warn(err)
    }
}

const checkCache = (resource: string, url: string): boolean => {
    const cache = localStorage.getItem(`RaStore.${resource}.${url}`)
    if (cache !== null) {
        const cacheData = JSON.parse(cache);
        if (cacheData?.time > (new Date().getTime() - 2 * 1000)) {
            return true;
        }
    }
    return false
}

const removeCache = (resource: string, url: string) => {
    localStorage.removeItem(`RaStore.${resource}.${url}`);
}

const getCache = (resource: string, url: string): any => {
    const cache = localStorage.getItem(`RaStore.${resource}.${url}`) || "{}";
    const cacheData = JSON.parse(cache);
    return cacheData;
}

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export default (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (inpResource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const resource = inpResource == "configs1" ? "configs" : inpResource;
        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query: any = {
            sort: `${field},${order.toLowerCase()}`,
            page: page - 1,
            size: perPage,
        };
        Object.keys(params.filter).forEach(k => {
            if (k.startsWith("tmp_")) return;
            query[k.replaceAll("_", ".")] = params.filter[k];
        })
        // check local state
        const url = `${apiUrl}/${resource}?${decodeURIComponent(new URLSearchParams(query).toString())}`;

        if (checkCache(inpResource, url)) {
            return Promise.resolve(getCache(inpResource, url)?.response);
        }

        removeCache(inpResource, url);

        const options =
            countHeader === 'Content-Range'
                ? {
                    // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                    headers: new Headers({
                        Range: `${resource}=${rangeStart}-${rangeEnd}`,
                    }),
                }
                : {};


        return httpClient(url, options).then(({headers, json}) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }

            const data = headers.get(countHeader) || '0'
            const response = {
                data: json,
                total: parseInt(data),
            };

            setCache(resource, url, {
                time: new Date().getTime(),
                url: url,
                response: response
            })
            return response;
        });
    },


    // ok
    getOne: (resource, params) => {
        if (params.id === "undefined" || params.id === 0) {
            return new Promise((resolve, reject) => {
                const newRecord: RaRecord<Identifier> = {id: 0};
                const newData: any = {data: newRecord};
                resolve(newData)
            });
        }

        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
            data: json,
        }))
    },

    getMany: (resource, params) => {
        const url = `${apiUrl}/${resource}?id.in=${params.ids.join(",")}`;
        if (checkCache(resource, url)) {
            return Promise.resolve(getCache(resource, url)?.response);
        }

        removeCache(resource, url);

        return httpClient(url).then(({json}) => {
            const response = {data: json}
            setCache(resource, url, {
                time: new Date().getTime(),
                url: url,
                response: response
            })
            return response;
        });
    },

    // ok
    getManyReference: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;
        const target = params.target.includes(".in") ? params.target : `${params.target}.equals`;
        if (perPage === 0) {
            return Promise.resolve({data: [], total: 0});
        }
        const query: any = {
            sort: `${field},${order.toLowerCase()}`,
            page: page - 1,
            size: perPage,
            [target]: params.id
        };

        Object.keys(params.filter).forEach(k => {
            query[k.replaceAll("_", ".")] = params.filter[k];
        })

        const url = `${apiUrl}/${resource}?${decodeURIComponent(new URLSearchParams(query).toString())}`;

        if (checkCache(resource, url)) {
            return Promise.resolve(getCache(resource, url)?.response);
        }

        removeCache(resource, url);


        const options =
            countHeader === 'Content-Range'
                ? {
                    // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                    headers: new Headers({
                        Range: `${resource}=${rangeStart}-${rangeEnd}`,
                    }),
                }
                : {};

        return httpClient(url, options).then(({headers, json}) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            const response = {
                data: json,
                total: 1,
            };
            setCache(resource, url, {
                time: new Date().getTime(),
                url: url,
                response: response
            })
            return response;
        });
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json})),

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({data: responses.map(({json}) => json.id)})),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json})),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
        }).then(({json}) => ({data: json})),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                })
            )
        ).then(responses => ({
            data: responses.map(({json}) => ''),
        })),
});