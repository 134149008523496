import {CommonInputProps, useInput, useRecordContext} from "react-admin";

import {ReactElement, useEffect, useState} from "react";
import {Button} from "@mui/material";
import {isEmptyObject} from "./util";

interface Props {
    onClick?: any
    startIcon?: any
    text?: any
    source: string
}

const DoSomethingToRecord = (props: Props) => {
    const {source, onClick, text, startIcon} = props
    const record = useRecordContext<any>();
    if (!record || isEmptyObject(record[source])) {
        return null;
    }

    return (<>
        <Button onClick={() => {
            onClick(record[source])
        }} startIcon={startIcon}>
            {text}
        </Button>
    </>)
}

export default DoSomethingToRecord