import {Create} from "react-admin";
import * as React from "react";
import {EntityTitle, FormWebsite, transformWebsiteData} from "./Edit";


const EntityCreate = () => (
    <Create title={<EntityTitle/>} transform={transformWebsiteData}>
        <FormWebsite/>
    </Create>
);

export default EntityCreate