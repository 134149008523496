import * as React from 'react';
import {
    FormDataConsumer,
    TextInput,
    TextField,
    useInput,
    CommonInputProps,
    SelectArrayInput, SelectInput
} from 'react-admin';
import {
    Chip,
    Grid,
    List,
    ListItem, ListItemIcon, ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import PathIcon from '@mui/icons-material/Person3';

interface Props extends CommonInputProps {
    source: string,
    resource?: string,
    isRequired?: boolean,
    sx?: any
}

interface PathFieldProps {
    sx?: any
}


const PathView = (props: Props) => {
    const {source, isRequired, sx, ...rest} = props;
    const {field} = useInput({source})

    const [localState, setLocalState] = useState(field.value as any);
    const [runState, setRunState] = useState({headerSelected: ''});
    useEffect(() => {
        setLocalState(field.value)
    }, [field.value])
    const theme = useTheme();

    const getThemeSecondaryColor = (idx: any, header: any) => {
        return header === runState.headerSelected ? theme.palette.secondary.light : '';
    };

    const clickOnHeader = (idx: any, header: any) => {
        setRunState({...runState, headerSelected: header});
    };

    const resetHeader = (name: any) => {
        if (localState?.headers) {
            localState.headers[name] = '';
            setLocalState({...localState, headers: {...localState?.headers}});
        }
    };
    const mapHeaders = [
        'Dimension.AD_EXCHANGE_NETWORK_PARTNER_NAME',
        'Dimension.AD_EXCHANGE_DATE',
        'Dimension.AD_EXCHANGE_DATE_TO',
        'Column.AD_EXCHANGE_ESTIMATED_REVENUE',
        'Column.AD_EXCHANGE_AD_REQUESTS',
        'Column.AD_EXCHANGE_CLICKS',
        'Column.AD_EXCHANGE_IMPRESSIONS',
        'Column.AD_EXCHANGE_CPC',
        'Column.AD_EXCHANGE_CTR',
        'Column.AD_EXCHANGE_AD_ECPM',
    ];

    const selectMap = (name: any) => {
        if (runState.headerSelected === '') {
            // alert("Select header first");
            return;
        }
        if (localState?.headers) {
            localState.headers[runState.headerSelected] = name;
            setLocalState({...localState, headers: {...localState?.headers}});
            setRunState({...runState, headerSelected: ''});
            field.onChange({...localState})
        }
    };

    return (<Grid container spacing={1}>
        <div style={{display: "none"}}>
            <Grid item xs={12} sm={12} md={4}>
                <TextField source="extra.id"/>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <TextField source="extra.name"/>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <TextField source="extra.sheet"/>
            </Grid>
        </div>
        <Grid item xs={12} sm={12} md={4}>
            {<SelectArrayInput
                fullWidth
                source="extra.vnd_columns"
                choices={Object.keys(localState.headers).map((r, index) => {
                    return {id: r, name: r};
                })}
            />}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
            {<SelectArrayInput
                fullWidth
                source="extra.vat_columns"
                choices={Object.keys(localState.headers).map((r, index) => {
                    return {id: r, name: r};
                })}
            />}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
            {<SelectInput
                fullWidth
                source="extra.thousand_separator"
                choices={[{id: ",", name: "Dấu phảy [,]"}, {id: ".", name: "Dấu chấm [.]"}]}
            />}
        </Grid>
        <Grid item xs={12} sm={12} md={runState.headerSelected !== '' ? 7 : 12}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Map</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localState?.headers &&
                        Object.keys(localState?.headers).map((header, idx) => {
                            const key = `map-key-${idx}`;
                            return (
                                <TableRow
                                    sx={{background: getThemeSecondaryColor(idx, header)}}
                                    data-value={header}
                                    data-name={header}
                                    key={key}
                                    onClick={event => {
                                        clickOnHeader(idx, header);
                                    }}
                                >
                                    <TableCell>{header}</TableCell>
                                    <TableCell>{localState?.headers[header]}</TableCell>
                                    <TableCell>
                                        {localState?.headers[header] !== '' && (
                                            <Chip label={"delete"} color={"warning"}
                                                  onClick={() => {
                                                      resetHeader(header);
                                                  }}/>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        {runState.headerSelected !== '' && <Grid item xs={12} sm={12} md={5}>
            <List dense={false}>
                {mapHeaders.filter(f => {
                    return !Object.keys(localState?.headers).map(k => {
                        return localState?.headers[k]
                    }).includes(f);
                }).map(ad => {
                    const key = `mapping-selected-${ad}`;
                    return (
                        <ListItem
                            key={key}
                            onClick={() => {
                                selectMap(ad);
                            }}
                        >
                            <ListItemIcon>
                                <PathIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={ad}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Grid>}
    </Grid>)
}

const PathField = (props: PathFieldProps) => {
    const {sx} = props;
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => {
                if (formData.type !== "Google Sheet") {
                    return <TextInput sx={sx} source="path" {...rest} disabled={true}/>
                }
                return <PathView sx={sx} source="extra"/>
            }}
        </FormDataConsumer>
    );
};


export default PathField