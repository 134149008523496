import {CommonInputProps, useInput, useRecordContext} from "react-admin";
import {isEmptyObject} from "./util";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {FIXED_FORMAT_OPTIONS} from "./MonyField";

interface Props extends CommonInputProps {
    name?: string
    lang: string
    sortable?: boolean
}

const FixedNumberField = (props: Props) => {
    const {source, lang} = props
    const record = useRecordContext<any>();
    const formatter = new Intl.NumberFormat(lang, FIXED_FORMAT_OPTIONS);
    if (!record) {
        return null;
    }
    const data = record[source];
    const getSuperSmallFontSize = (): any => {
        return "12";
    }
    if (isEmptyObject(data)) {
        return null;
    }

    if (data === "Infinity") {
        return <AllInclusiveIcon fontSize={getSuperSmallFontSize()}/>
    }

    return (<span>
        {formatter.format(data)}
    </span>)
}

export default FixedNumberField