import * as React from 'react';
import {Box, Button, Input, InputAdornment, InputBase} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {ResettableTextField, TextInput, useListContext, useTranslate, CommonInputProps} from 'react-admin';
import {useState} from "react";

interface Props extends CommonInputProps {
    source: string
    resettable?: boolean
    alwaysOn?: boolean
    name?:string
}

const SearchWithButton = (props: Props) => {
    const {source, resource, resettable} = props;
    const {
        displayedFilters,
        filterValues,
        setFilters
    } = useListContext();

    const [filterValue, setFilterValue] = useState(filterValues[source]);
    const translate = useTranslate();
    const searchClick = () => {
        const newFilter = {...filterValues};
        newFilter[source] = filterValue;
        setFilters(newFilter, displayedFilters);
    }

    const onChange = (evt: any) => {
        setFilterValue(evt.target.value)
    }

    const keyEvent = (evt: any) => {
        if (evt?.key === "Enter" || evt?.keyCode === 13) {
            searchClick();
        }
    }

    return (
        <Box display="flex" alignItems="flex-end">
            <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <ResettableTextField
                    label={`${translate(`resources.${resource}.filters.${source}`)}`}
                    name={`search-${source}`}
                    variant={"outlined"}
                    defaultValue={filterValue}
                    placeholder={"Search"}
                    onChange={onChange}
                    onKeyDown={keyEvent}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon color="disabled" onClick={() => searchClick()}/>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
        </Box>
    );
};


export default SearchWithButton