import {Button} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {CommonInputProps, useListContext} from "react-admin";
import {useEffect, useState} from "react";

interface Props extends CommonInputProps {

}

const SearchButton = (props: Props) => {
    const {
        filterValues,
        displayedFilters,
        setFilters
    } = useListContext();
    const [localState, setLocalState] = useState({color: "primary"});

    useEffect(() => {
        const keys = Object.keys(filterValues).filter(k => k.startsWith("tmp_"));
        const tmpValue = keys.map(k => JSON.stringify(filterValues[k])).join(",");
        const value = keys.map(k => JSON.stringify(filterValues[k.replace("tmp_", "")])).join(",")
        if (value !== tmpValue) {
            setLocalState({color: "warning"})
        }
    }, [filterValues])

    const applyFilters = () => {
        const newFilter = {} as any
        Object.keys(filterValues).forEach(key => {
            if (key.startsWith("tmp_")) {
                newFilter[key.replace("tmp_", "")] = filterValues[key];
                newFilter[key] = filterValues[key];
            }
        })
        setFilters(newFilter, displayedFilters);
        setLocalState({color: "primary"});
    }
    const getColor = (): any => {
        return localState.color;
    }

    return (
        <Button
            sx={{marginBottom: 1}}
            size="small"
            color={getColor()}
            startIcon={<SearchIcon/>}
            onClick={applyFilters}
        >
            Apply filter
        </Button>
    );
};

export default SearchButton;