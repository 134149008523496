import {Create} from "react-admin";
import * as React from "react";
import {EntityTitle, AdUnitForm, transformAdvertiserData} from "./AdvertiserFactorEdit";
import {useState} from "react";


const AdvertiserFactorCreate = () => {
    const [localState, setLocalState] = useState({
        dfps: [],
        website: 0,
        sources: [],
        addNew: null,
        movedDfps: []
    });

    return (<Create resource={"configs"} title={<EntityTitle/>} transform={transformAdvertiserData} redirect={"/settings-advertiser"}>
        <AdUnitForm state={localState} setState={setLocalState}/>
    </Create>)
};

export default AdvertiserFactorCreate