import * as React from 'react';
import {ReferenceField, TextField, ReferenceFieldProps} from 'react-admin';

const WebsiteReferenceField = (
    props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
        source?: string;
        link?: boolean;
    }
) => (
    <ReferenceField source="website.id" reference="websites" {...props}>
        <TextField source={"domain"}/>
    </ReferenceField>
);

WebsiteReferenceField.defaultProps = {
    source: 'website.id',
    link: true,
};

export default WebsiteReferenceField;