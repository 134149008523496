import AdUnitIcon from '@mui/icons-material/Javascript';

import CodeList from './List';
import CodeEdit from './Edit';
import CodeCreate from './Create';

export default {
    list: CodeList,
    edit: CodeEdit,
    create: CodeCreate,
    icon: AdUnitIcon,
};
