import * as React from 'react';
import {
    useGetIdentity,
    useGetList,
    List,
    Datagrid,
    TextField,
    TopToolbar
} from 'react-admin';
import {Button} from "@mui/material";
import {fetchJson} from "../App";


const ListActions = () => {
    const handleOnclick = () => {
        fetchJson("/api/google.callback.uri").then(res => {
            window.open(res?.body, "_blank");
        })
    }
    return (
        <TopToolbar>
            <Button onClick={handleOnclick}>Add</Button>
        </TopToolbar>
    );
};

const AdsenseAccount = ({title = 'Config date'}) => {

    const {identity, isLoading: identityLoading} = useGetIdentity();
    const isSuperAdmin = identity?.authorities.includes('ROLE_ADMIN');

    if (!isSuperAdmin) return null;

    return (
        <List resource={"configs1"}  filterDefaultValues={{"type.Equals": "oauth"}} actions={<ListActions/>}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="name"/>
                <TextField source="value"/>
                <TextField source="type"/>
            </Datagrid>
        </List>
    );
};

export default AdsenseAccount;