import * as React from 'react';
import {
    Children,
    cloneElement,
    MouseEvent,
    MouseEventHandler,
    isValidElement,
    ReactElement,
    ReactNode,
    useMemo,
} from 'react';
import {TableCell, TableRow, Typography} from '@mui/material';
import clsx from 'clsx';
import {RaRecord} from 'ra-core';
import {
    ArrayInputContextValue,
    SimpleFormIteratorClasses
} from "react-admin";
import {TableFormIteratorItemContext, TableFormIteratorItemContextValue} from "./TableFormIteratorItemContext";
import {isEmptyObject} from "./util";
import {useTableFormIterator} from "./useTableFormIterator";


export const TableFormIteratorItem = React.forwardRef(
    (props: TableFormIteratorItemProps, ref: any) => {
        const {
            children,
            disabled,
            disableReordering,
            disableRemove,
            getItemLabel,
            index,
            inline = false,
            member,
            record,
            className,
            removeButton,
            reOrderButtons,
            resource,
            source,
        } = props;

        const {total, reOrder, remove} = useTableFormIterator();
        // Returns a boolean to indicate whether to disable the remove button for certain fields.
        // If disableRemove is a function, then call the function with the current record to
        // determining if the button should be disabled. Otherwise, use a boolean property that
        // enables or disables the button for all of the fields.
        const disableRemoveField = (record: RaRecord) => {
            if (typeof disableRemove === 'boolean') {
                return disableRemove;
            }
            return disableRemove && disableRemove(record);
        };

        // remove field and call the onClick event of the button passed as removeButton prop
        const handleRemoveButtonClick = (
            originalOnClickHandler: MouseEventHandler,
            index: number
        ) => (event: MouseEvent) => {
            remove(index);
            if (originalOnClickHandler) {
                originalOnClickHandler(event);
            }
        };
        const context = useMemo<TableFormIteratorItemContextValue>(
            () => ({
                index,
                total,
                reOrder: newIndex => reOrder(index, newIndex),
                remove: () => {
                    remove(index)
                },
            }),
            [index, total, reOrder, remove]
        );

        const label =
            typeof getItemLabel === 'function'
                ? getItemLabel(index)
                : getItemLabel;
        const cell = () => {
            return <TableCell className={`${SimpleFormIteratorClasses.line} ${className}`}>
                {label && (
                    <Typography
                        variant="body2"
                        className={SimpleFormIteratorClasses.index}
                    >
                        {label}
                    </Typography>
                )}
                {Children.map(
                    children,
                    (input: any, index2) => {
                        if (!isValidElement<any>(input)) {
                            return null;
                        }
                        const {source, ...inputProps} = input.props;
                        return cloneElement(input, {
                            source: source
                                ? `${member}.${source}`
                                : member,
                            index: source ? undefined : index2,
                            resource,
                            disabled,
                            ...inputProps,
                        });
                    }
                )}
                {!disabled && (
                    <span className={SimpleFormIteratorClasses.action}>
                            {!disableReordering &&
                            cloneElement(reOrderButtons, {
                                index,
                                max: total,
                                reOrder,
                                className: clsx(
                                    'button-reorder',
                                    `button-reorder-${source}-${index}`
                                ),
                            })}

                        {!disableRemoveField(record) &&
                        cloneElement(removeButton, {
                            onClick: handleRemoveButtonClick(
                                removeButton.props.onClick,
                                index
                            ),
                            className: clsx(
                                'button-remove',
                                `button-remove-${source}-${index}`
                            ),
                        })}
                        </span>
                )}
            </TableCell>;
        }
        const cellInLine = () => <><TableRow className={className} ref={ref}>
            {label && (
                <Typography
                    variant="body2"
                    className={SimpleFormIteratorClasses.index}
                >
                    {label}
                </Typography>
            )}
            {Children.map(
                children,
                (input: any, index2) => {
                    if (!isValidElement<any>(input)) {
                        return null;
                    }
                    const {source, ...inputProps} = input.props;
                    if (isEmptyObject(source)) {
                        return null;
                    }
                    return <TableCell>
                        {cloneElement(input, {
                            source: source
                                ? `${member}.${source}`
                                : member,
                            index: source ? undefined : index2,
                            resource,
                            disabled,
                            ...inputProps,
                        })}
                    </TableCell>;
                }
            )}
            {!disabled && (
                <TableCell>
                    <span className={SimpleFormIteratorClasses.action}>
                            {!disableReordering &&
                            cloneElement(reOrderButtons, {
                                index,
                                max: total,
                                reOrder,
                                className: clsx(
                                    'button-reorder',
                                    `button-reorder-${source}-${index}`
                                ),
                            })}

                        {!disableRemoveField(record) &&
                        cloneElement(removeButton, {
                            onClick: handleRemoveButtonClick(
                                removeButton.props.onClick,
                                index
                            ),
                            className: clsx(
                                'button-remove',
                                `button-remove-${source}-${index}`
                            ),
                        })}
                        </span>
                </TableCell>
            )}
        </TableRow>{Children.map(
            children,
            (input: any, index2) => {
                if (!isValidElement<any>(input)) {
                    return null;
                }
                const {source, children: inputChildren, ...inputProps} = input.props;
                if (!isEmptyObject(source)) {
                    return null;
                }
                const getColSpan = (child: any) => {
                    return child.filter((c:any) => c.type !== "span").length + 1
                }
                const colSpan = getColSpan(children);
                return <>{Children.map(
                    inputChildren,
                    (childOfInput: any, index2) => {
                        if (!isValidElement<any>(childOfInput)) {
                            return null;
                        }
                        const {source: childSource, ...inputProps} = childOfInput.props;
                        if (isEmptyObject(childSource)) {
                            return null;
                        }
                        return <TableRow className={className}>
                            <TableCell colSpan={colSpan}>
                                {cloneElement(childOfInput, {
                                    source: childSource
                                        ? `${member}.${childSource}`
                                        : member,
                                    index: childSource ? undefined : index2,
                                    resource,
                                    disabled,
                                    ...inputProps,
                                })}
                            </TableCell>
                        </TableRow>;
                    }
                )}</>;
            }
        )}</>
        return (
            <TableFormIteratorItemContext.Provider value={context}>
                {!inline && <TableRow className={className} ref={ref}>
                    {cell()}
                </TableRow>}
                {inline && <>
                    {cellInLine()}
                </>}
            </TableFormIteratorItemContext.Provider>
        );
    }
);

export type DisableRemoveFunction = (record: RaRecord) => boolean;

type GetItemLabelFunc = (index: number) => string | ReactElement;

export type TableFormIteratorItemProps = Partial<ArrayInputContextValue> & {
    className?: string;
    children?: ReactNode;
    disabled?: boolean;
    disableRemove?: boolean | DisableRemoveFunction;
    disableReordering?: boolean;
    getItemLabel?: boolean | GetItemLabelFunc;
    index: number;
    inline?: boolean;
    member: string;
    onRemoveField: (index: number) => void;
    onReorder: (origin: number, destination: number) => void;
    record: RaRecord;
    removeButton?: any;
    reOrderButtons?: any;
    resource: string;
    source: string;
};
