import * as React from 'react';
import {
    CommonInputProps, useInput
} from 'react-admin';
import {Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme} from "@mui/material";
import {useEffect} from "react";
import {isEmptyObject, isEmptyString} from "../fields/util";

interface Props extends CommonInputProps {
    source: string,
    resource?: string,
    isRequired?: boolean,
    sx?: any,
    onDelete: any,
    state?: any,
}

interface PathFieldProps {
    sx?: any
    onDelete?: any;
    state?: any;
}


const DfpView = (props: Props) => {
    const theme = useTheme();
    const {source, onDelete, state: remoteState} = props;
    const {field} = useInput({source})

    const deleteAndPush = (id: any) => {
        field.onChange(field.value.filter((dfp: any) => dfp.id !== id))
        onDelete({...field.value.find((dfp: any) => dfp.id === id), color: "warning"})
    }
    useEffect(() => {
        if (!isEmptyObject(remoteState?.addNew)) {
            field.onChange([...field.value, remoteState?.addNew]);
        }
    }, [remoteState?.addNew])

    const getColor = (c: any): any => {
        return c === "primary" ? theme.palette.primary.light : theme.palette.background
    }
    return (<TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Source</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Publisher</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {!isEmptyString(field.value) && field.value.map((dfp: any, idx: number) => {
                    const key = `map-key-${idx}`;
                    return (
                        <TableRow
                            key={key}
                            style={{
                                backgroundColor: getColor(dfp?.color),
                            }}
                        >
                            <TableCell>{dfp?.source?.name}</TableCell>
                            <TableCell>{dfp.name}</TableCell>
                            <TableCell>{dfp?.source?.type}</TableCell>
                            <TableCell>{dfp?.user?.login}</TableCell>
                            <TableCell><Chip label={"unset"} color={"warning"} onClick={() => {
                                deleteAndPush(dfp.id)
                            }}/></TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </TableContainer>)
}

const DfpField = (props: PathFieldProps) => {
    const {sx, onDelete, state} = props;
    return (
        <DfpView sx={sx} source="adDFPs" onDelete={onDelete} state={state}/>
    );
};


export default DfpField