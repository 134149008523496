import * as React from 'react';
import {ReferenceField, TextField, ReferenceFieldProps} from 'react-admin';

const PublisherReferenceField = (
    props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
        source?: string;
    }
) => (
    <ReferenceField source="user.id" reference="admin/users" {...props}>
        <TextField source={"login"}/>
    </ReferenceField>
);

PublisherReferenceField.defaultProps = {
    source: 'user.id',
};

export default PublisherReferenceField;