import * as React from 'react';
import {FormDataConsumer, TextInput, TextField, useTranslate} from 'react-admin';

interface Props {
    source: string,
    resource?: string,
    isEdit: any
    isRequired?: boolean,
    sx?: any
}

const CreateOnlyTextInput = (props: Props) => {
    const {source, isEdit, isRequired, sx} = props;

    return (
        <FormDataConsumer>
            {({formData, ...rest}) =>
                isEdit &&
                <TextInput fullWidth sx={sx} isRequired={isRequired} source={source} {...rest} disabled={!isEdit(formData)}/>
            }
        </FormDataConsumer>
    );
};


export default CreateOnlyTextInput