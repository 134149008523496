import * as React from 'react';
import {
    Edit,
    TextInput,
    useTranslate,
    Form,
    useRecordContext,
    ReferenceInput,
    AutocompleteInput,
    isEmpty, ArrayInput, SimpleFormIterator, FormDataConsumer, SelectInput
} from 'react-admin';
import {Card, CardContent, Box, Grid} from '@mui/material';
import DatePickerFormatAble from "../fields/DatePickerFormatable";

import {FactorChoices, isEmptyObject} from "../fields/util";
import {useEffect, useMemo, useState} from "react";

import './style.css';
import {EditToolbar} from "../fields/EditWithToolbar";

export const EntityTitle = () => {
    const record = useRecordContext<any>();
    return (<span>
        {isEmptyObject(record?.id) ? `Configs` : `Configs: ${record.display}`}
    </span>)
}

export const transformAdvertiserData = (data: any) => {
    return {
        ...data,
        value: JSON.stringify({"factor": data.factor, "map": data.map}),
    };
}

interface AdvertiserFormProps {
    state?: any;
    setState?: any;
}

const AdvertiserFactorEdit = () => {
    const [localState, setLocalState] = useState({
        id: 0
    });

    return (
        <Edit resource={"configs"} title={<EntityTitle/>} redirect={`/settings-advertiser/${localState.id}`}
              component="div"
              transform={transformAdvertiserData}>
            <AdUnitForm state={localState} setState={setLocalState}/>
        </Edit>
    )
};
const isNumeric = (x:any) => /^[+-]?\d+(\.\d+)?$/.test(x)

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;

    if (isEmptyObject(values.map)) {
        errors.map = 'ra.validation.required';
    }

    values.map.forEach((m:any) => {
        if (isEmptyObject(m.from)) {
            errors.map = 'ra.validation.required';
        }
        if (isEmptyObject(m.to)) {
            errors.map = 'ra.validation.required';
        }
        if (isEmptyObject(m.factor)) {
            errors.map = 'ra.validation.required';
        }
        if (!isNumeric(m.factor)) {
            errors.map = 'ra.validation.required';
        }
    })
    return errors;
};


export const AdUnitForm = (props: AdvertiserFormProps) => {
    const {state: localState, setState: setLocalState} = props;
    const record = useRecordContext();
    const recordFix = useMemo(() => {
        const values = (isEmpty(record) || isEmpty(JSON.parse(record.value))) ? {} : JSON.parse(record.value);
        return {
            ...record, factor: isEmpty(values.factor) ? 1 : values.factor,
            map: isEmpty(values.map) ? [] : values.map
        };
    }, [record])

    useEffect(() => {
        setLocalState({
            ...localState,
            id: record?.id
        })
    }, [record])

    return (
        <Box maxWidth="70em">
            <Form validate={validateForm} className={"formxxx"} record={recordFix}>
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>

                            <Grid item xs={12} sm={12} md={6}>
                                <DynamicReference/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <SelectInput
                                    isRequired
                                    source="type"
                                    defaultValue={"factor"}
                                    choices={FactorChoices}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput source={`factor`} helperText={false}/>
                                <ArrayInput source={`map`} name={`map`}>
                                    <SimpleFormIterator inline>
                                        <DatePickerFormatAble resource={"configs"} source={`from`} i18Key={"from"}
                                                              helperText={false}/>
                                        <DatePickerFormatAble resource={"configs"} source={`to`} i18Key={"to"}
                                                              helperText={false}/>
                                        <TextInput source={`factor`} helperText={false}/>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <EditToolbar field={"display"}/>
                </Card>
            </Form>
        </Box>
    );
};

const DynamicReference = () => {
    return <FormDataConsumer>
        {({formData, ...rest}) => {
            if (formData.type === "factor") {
                return <ReferenceInput source="name" reference="sources">
                    <AutocompleteInput fullWidth
                                       optionText={(choice?: any) =>
                                           choice?.id // the empty choice is { id: '' }
                                               ? `${choice.name}`
                                               : ''
                                       }
                                       filterToQuery={(s) => {
                                           return {name_contains: s};
                                       }}
                    />
                </ReferenceInput>
            }
            if (formData.type === "factor-user") {
                return <ReferenceInput source="name" reference="admin/users">
                    <AutocompleteInput fullWidth
                                       optionText={(choice?: any) =>
                                           choice?.id // the empty choice is { id: '' }
                                               ? `${choice.login}`
                                               : ''
                                       }
                                       filterToQuery={(s) => {
                                           return {email_contains: s};
                                       }}
                    />
                </ReferenceInput>
            }
            return null;
        }}
    </FormDataConsumer>
}

export default AdvertiserFactorEdit;
