import * as React from 'react';
import { Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {TextField, useRecordContext, useTranslate} from 'react-admin';

import MoneyField from "../fields/MonyField";
import LongField from "../fields/LongField";
import FixedNumberField from "../fields/FixedNumberField";
import ECPM from "../fields/EcpmField";

interface Props {
    moneyRate: any,
    lang: any,
    showMatchedRequest: boolean
}

const ReportShow = (props: Props) => {
    const {moneyRate, lang} = props;
    const record = useRecordContext<any>();
    if (!record) return null;
    const translate = useTranslate();
    const cols = props.showMatchedRequest ? ['adRequests', 'matchedRequests', 'clicks'] : ['adRequests', 'clicks'];
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell component="th"></TableCell>
                        <TableCell component="th" align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell scope="row">
                            {translate(`resources.report-ad-exchanges/report.fields.user`)}
                        </TableCell>
                        <TableCell scope="row">
                            <TextField source="user" sortable={false}/>
                        </TableCell>
                    </TableRow>
                    {cols.map((c, idx) => {
                        if ('' + record[c] === '0') {
                            return null
                        }
                        return (<TableRow
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            key={`c1-${idx}`}
                        >
                            <TableCell scope="row">
                                {translate(`resources.report-ad-exchanges/report.fields.${c}`)}
                            </TableCell>
                            <TableCell scope="row">
                                <LongField source={c} sortable={false} rate={moneyRate} lang={lang}/>
                            </TableCell>
                        </TableRow>)
                    })}

                    <TableRow
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell scope="row">
                            {translate(`resources.report-ad-exchanges/report.fields.coverage`)}
                        </TableCell>
                        <TableCell scope="row">
                            <FixedNumberField source="coverage" sortable={false} lang={lang}/>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell scope="row">
                            {translate(`resources.report-ad-exchanges/report.fields.ecpm`)}
                        </TableCell>
                        <TableCell scope="row">

                            {lang === "en" && <ECPM source="ECPM" rate={1} lang={lang} revCol={"revenue"}/>}
                            {lang === "vi" && <ECPM source="ECPM" rate={1} lang={lang} revCol={"revenueVnd"}/>}
                        </TableCell>
                    </TableRow>



                </TableBody>
            </Table>
        </TableContainer>
    );
};


export default ReportShow;