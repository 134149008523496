import {Create} from "react-admin";
import * as React from "react";
import {EntityTitle, EmbedCodeForm, transformAdvertiserData} from "./Edit";
import {useState} from "react";


const EntityCreate = () => {
    const [localState, setLocalState] = useState({
        dfps: [],
        website: 0,
        sources: [],
        addNew: null,
        movedDfps: []
    });

    return (<Create title={<EntityTitle/>} transform={transformAdvertiserData}>
        <EmbedCodeForm state={localState} setState={setLocalState}/>
    </Create>)
};

export default EntityCreate